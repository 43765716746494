import {
  Directive,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";

@Directive({
  selector: "[SpecialCharacterInput]",
})
export class SpecialCharacterInputDirective implements OnChanges {
  @Input() ngModel;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private element: ElementRef) {}

  ngOnChanges(data: SimpleChanges) {
    if (data.ngModel && data.ngModel.currentValue) {
      this.removeSpecialCharacters(data.ngModel.currentValue);
    }
  }

  removeSpecialCharacters(value) {
    value = this.ngModel ? this.ngModel : this.element.nativeElement.value;

    var str = value;
    if (!str.replace(/\s/g, "").length) {
      alert("Space as first character is not allowed");
      return;
    }

    const actualData = value;
    let data = value;

    // remove all special characters
    while (/[""!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/i.test(data)) {
      data = data.replace(/[""!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/i, "");
    }

    if (actualData !== data) {
      this.ngModel = data;
      this.element.nativeElement.value = data;
      this.ngModelChange.emit(data);
    }
  }
}

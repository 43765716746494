import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { MainService } from "../../services/main.service";
import { SignupService } from "../../services/signup/signup.service";

class Signup {
  constructor(
    public firstname: string = "",
    public lastname: string = "",
    public email: string = "",
    public mobile: string = "",
    public userrole: string = "2",
    public volunteering_mode: string = null,
    public address: string = "",
    public country: string = null,
    public state: string = null,
    public city: string = null,
    public pincode: string = "",
    public dateofbirth: any,
    public gender: string = null,
    public password: string = "",
    public cunfurmPassword: string = "",
    public website_url: string = "http://localhost:4200/",
    public interestedAsVolunteer: string = null,
    public interestedAsPanelMember: string = null,
    public occupation: string = null
  ) {}
}

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  constructor(
    private signupService: SignupService,
    private mainService: MainService
  ) {
    this.minDate = this.mainService.maxAge;
    this.maxDate = this.mainService.minAge;
  }

  public errorRequired = "is required.";
  public error;
  public countrys;
  public states;
  public citys;
  public citysStatus = false;
  public setPassword = false;
  public volunteer;
  public validCheck = false;
  public Msg;
  public MsgAction = false;
  public spinnerLoader = false;
  public myRecaptcha = false;
  // checkboxValue: boolean;
  // model: Signup = new Signup();

  @ViewChild("f") form: any;

  focusOutFunction(e) {}

  @Output() dismissModal = new EventEmitter<string>();

  dismiss() {
    this.dismissModal.next();
  }

  ngOnInit() {
    this.getAllCountry();
    this.getAllVolunteerOptions();
  }

  // POST WITH API
  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }

    this.spinnerLoader = true;
    const data = new HttpParams();
    // .set("firstname", this.model.firstname)
    // .set("lastname", this.model.lastname)
    // .set("email", this.model.email)
    // .set("mobile", this.model.mobile)
    // .set("userrole", this.model.userrole)
    // .set("address", this.model.address)
    // .set("country", this.model.country)
    // .set("state", this.model.state)
    // .set("city", this.model.city)
    // .set("pincode", this.model.pincode)
    // .set("volunteering_mode", this.model.volunteering_mode)
    // .set("dateofbirth", this.model.dateofbirth)
    // .set("gender", this.model.gender)
    // .set("password", this.model.password)
    // .set("website_url", this.model.website_url)
    // .set("interestedAsVolunteer", this.model.interestedAsVolunteer)
    // .set("interestedAsPanelMember", this.model.interestedAsPanelMember)
    // .set("occupation", this.model.occupation);

    this.signupService.post(data).subscribe((response: any) => {
      this.error = response.error;
      if (response.status == "true") {
        this.Msg = "Thank you for sign up";
        this.form.reset();
        this.myRecaptcha = true;
        setTimeout(() => {
          this.dismiss();
        }, 3000);
      } else {
        this.Msg = this.error;
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }

  getAllCountry() {
    this.mainService.getAllCountry().subscribe((response) => {
      this.countrys = response.data;
    });
  }
  // getAllStatesByCountryId
  getAllStatesByCountryId(countryId) {
    let data = new HttpParams().set("countryId", countryId);
    this.mainService
      .getAllStatesByCountryId(data)
      .subscribe((response: any) => {
        this.states = response.data;
      });
  }

  // getAllStatesByCountryId
  getAllCityByStateId(stateId) {
    let data = new HttpParams().set("stateId", stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;
      this.citysStatus = response.status;
    });
  }
  getAllVolunteerOptions() {
    this.mainService.getAllVolunteerOptions().subscribe((response) => {
      this.volunteer = response.data;
    });
  }

  numberOnly(event): any {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

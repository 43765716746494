import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { MainService } from "../../services/main.service";
import { environment } from "../../../environments/environment";

class Book {
  constructor(public userComments: string = "", public userName: string = "") {}
}
@Component({
  selector: "app-research",
  templateUrl: "./research.component.html",
  styleUrls: ["./research.component.scss"],
})
export class ResearchComponent implements OnInit {
  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private mainService: MainService
  ) {}

  spinnerLoader = false;
  model: Book = new Book();
  @ViewChild("f") form: any;
  validCheck;
  status;
  public bookComments;

  public metaUrl = window.location.href;
  public metaTitle = "WAVF book";
  public metaDescription =
    "WAVF or World Anti Voyeurism Forum is an organization against the offence of Voyeurism. Voyeurism is the practice of gaining sexual pleasure from watching or recording others when they are naked or engaged in sexual activity. In order to generate public awareness, out NGO has drafted a book called “You are being secretly watched” which also contains a draft bill of law. To read the online free PDF copy of this book, click on the link below. You can also visit our website www.wavf.org.";
  public metaImage = environment.imageUrlShare + "book.png";
  public metaTags =
    "WAVF, AntiVoyeurism, lawbook, modellaw, privacy, NGO, cybersecurity, peepingtom";

  ngOnInit() {
    this.titleService.setTitle("Our Book ");
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.getComments();
    window.scroll(0, 0);
  }
  downloadCount() {
    let data;
    this.mainService.downloadBookCount(data).subscribe((response) => {});
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    const data = new HttpParams()
      .set("firstname", this.model.userName)
      .set("comment", this.model.userComments);
    const urlApi = "user/book_comment";
    this.mainService.postApi(urlApi, data).subscribe((response) => {
      this.status = response;
      if ((response = true)) {
        this.toastr.success(
          "Thank you for your comment! Your comment will be displayed after approval from WAVF",
          "",
          {
            positionClass: "toast-bottom-center",
          }
        );
        this.validCheck = false;
        this.form.reset();
      } else {
        this.toastr.error("Something went wrong.", "", {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
    });
  }

  getComments() {
    const urlApi = "user/get_approved_comment_for_book";
    let data;
    this.mainService.postApi(urlApi, data).subscribe((response) => {
      this.bookComments = response.book_comment_details;
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseContentType } from "@angular/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };

@Injectable({
  providedIn: "root",
})
export class SearchService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  insta_search(countryId): Observable<any> {
    return this.http.post(`${this.apiURL}user/insta_search`, countryId, {
      headers,
    });
  }
}

import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { UsersService } from '../../services/users.service';
@Component({
  selector: 'app-wavf',
  templateUrl: './wavf.component.html',
  styleUrls: ['./wavf.component.scss']
})
export class WavfComponent implements OnInit {
  constructor(private wavf:UsersService) { }
  
  public data : any;
  @Input() itemLimit : any;
  @Input() divClass : any;

  ngOnInit() { 
    this.data = this.wavf.wavf;
  }

}


//import { Component, Input, ElementRef, OnChanges} from '@angular/core';

// @Component({
//     selector: 'read-more',
//     template: `
//         <div [class.collapsed]="isCollapsed">
//             <ng-content></ng-content>
//         </div>
//         <div (click)="isCollapsed = !isCollapsed" class="readmore">Read more</div>
//     `,
//     styles: [`
//         div.collapsed {
//             height: 200px;
//             overflow: hidden;
//             text-align:justify;
//         }
//         div.readmore{
//             background:#FBC01E;
//             color:#FB0002;
//             width:100px;
//             font-weight:700;
//             padding:5px 10px;
//             border-radius:5px;
//             margin:20px 0
//         }
//     `]
// })


// export class ReadMoreComponent {
//     isCollapsed = true;
// }



import { Component, Input, ElementRef, OnChanges} from '@angular/core';

@Component({    
    selector: 'read-more',
    // template: `
    //     <div [innerHTML]="currentText">
    //     </div>
    //         <a [class.hidden]="hideToggle" (click)="toggleView()">Read {{isCollapsed? 'more':'less'}}</a>
    // `
template: `
        <div [class.collapsed]="isCollapsed" >
            <ng-content></ng-content>
        </div>
        <a (click)="toggleView()" class="readmore" > {{isCollapsed? 'Read more...':''}}</a>
       
    `,
    styles: [`
        div.collapsed {
            height: 200px;
            overflow: hidden;
            text-align:justify;
        }
        div.readmore{
            
            color:#FB0002;
            width:100px;
            font-weight:700;
            padding:5px 0px;
            
            margin:7px 0
        }
    `]
 })

export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength: number = 100;
    currentText: string;
    hideToggle: boolean = true;

    public isCollapsed: boolean = true;

    constructor(private elementRef: ElementRef) {

    }
    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView() {
        if (!this.text || this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            return;
        }
        this.hideToggle = false;
        if (this.isCollapsed == true) {
            this.currentText = this.text.substring(0, this.maxLength) + "...";
        } else if(this.isCollapsed == false)  {
            this.currentText = this.text;
        }

    }
    ngOnChanges() {
        this.determineView();       
    }
}
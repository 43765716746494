import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UsersService } from "../../services/users.service";
import { LocationStrategy } from "@angular/common";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "../../services/authentication.service";
import { Router, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/filter";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class HeaderComponent implements OnInit {
  constructor(
    public config: NgbModalConfig,
    private link: UsersService,
    private url: LocationStrategy,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private router: Router,
    private activatedRout: ActivatedRoute
  ) {
    this.authService.getevent().subscribe((event) => {
      if (event.text != "") {
        let element: HTMLElement = document.getElementById(
          event.text
        ) as HTMLElement;
        element.click();
      }
    });
  }

  closeResult: string;
  @ViewChild("lgModal") modal: ElementRef;
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  Curl;
  links;
  someClass = false;
  isCollapsed = true;
  show_header = true;
  logIn = false;
  logo = "assets/assets/images/logo/logo.png";
  public currentUserName;
  public location = window.location.href;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    let element = document.querySelector(".navbar");
    if (this.router.url === "/home") {
      if (window.pageYOffset < 400) {
        element.classList.add("theme_menu_1");
      } else if (window.pageYOffset > 400) {
        element.classList.replace("theme_menu_1", "theme_menu");
      }
    }
    // else if(this.router.url !== "/home"){
    //   if (window.pageYOffset < 10) {
    //     element.classList.add('theme_menu_1')
    // } else if(window.pageYOffset > 10) {
    //     element.classList.replace('theme_menu_1', 'theme_menu');
    // }
    // }
  }

  scrollHandler(event) {
    if (window.pageYOffset > 20) {
      this.someClass = true;
    } else {
      this.someClass = false;
    }
  }
  openStatic(e) {
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.open(e);
  }
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser != null) {
      this.currentUserName = currentUser.firstname;
    }

    this.show_header = true;
    this.links = this.link.links;

    if (currentUser) {
      this.logIn = true;
    } else {
      this.logIn = false;
    }
    this.router.events
      .filter((event: any) => event instanceof NavigationEnd)
      .subscribe((event) => {
        this.Curl = event.url;
        if (this.Curl == "/welcome") {
          let element: HTMLElement = document.getElementById(
            "Welcome"
          ) as HTMLElement;
          element.click();
        }
      });
  }

  loggedIn() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.logIn = true;
      window.location.reload();
    } else {
      this.logIn = false;
      this.router.navigate(["/"]);
    }
  }
  logOut() {
    this.authService.logout();
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser) {
      this.logIn = true;
      window.location.reload();
    } else {
      this.logIn = false;
      this.router.navigate([location]);
    }
  }
}

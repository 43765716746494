import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MetaModule,
  MetaLoader,
  MetaStaticLoader,
  PageTitlePositioning,
} from "@ngx-meta/core";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "./app.component";
import {
  NgbModule,
  NgbPaginationModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";
import { MenusComponent } from "./shared/menus/menus.component";
import { HeaderComponent } from "./core/header/header.component";
import { FooterComponent } from "./core/footer/footer.component";
import { CarouselComponent } from "./template/carousel/carousel.component";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { FeaturesComponent } from "./template/features/features.component";
import { ServicesComponent } from "./template/services/services.component";

import { SignupService } from "./services/signup/signup.service";
import { LoginComponent } from "./accounts/login/login.component";

import { AppRoutingModule } from "./routes/app.rauting";
import { Ng2OdometerModule } from "ng2-odometer";

// pages Component
import { HomeComponent } from "./pages/home/home.component";
import { ForumComponent } from "./pages/forum/forum.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { AccredetationComponent } from "./pages/accredetation/accredetation.component";
import { AffiliationComponent } from "./pages/affiliation/affiliation.component";
import { VolunteerComponent } from "./pages/volunteer/volunteer.component";
import { EventComponent } from "./pages/event/event.component";
import { PhotoGalleryComponent } from "./pages/photo-gallery/photo-gallery.component";
import { VideoGalleryComponent } from "./pages/video-gallery/video-gallery.component";
import { NewsGalleryComponent } from "./pages/news-gallery/news-gallery.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { RecentComponent } from "./pages/blog/recent.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { VoyeurismFreeComponent } from "./pages/voyeurism-free/voyeurism-free.component";
import { OtherMaterialComponent } from "./pages/other-material/other-material.component";
import { ResearchComponent } from "./pages/research/research.component";
import { CaseStudyComponent } from "./pages/case-study/case-study.component";
import { LawDraftComponent } from "./pages/law-draft/law-draft.component";
import { WavfComponent } from "./template/wavf/wavf.component";
import { LawsComponent } from "./template/laws/laws.component";
import { TeamComponent } from "./template/team/team.component";
import { SocialLinkComponent } from "./shared/social-link/social-link.component";

import { SocialLoginModule, AuthServiceConfig } from "angular4-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular4-social-login";
import { UpcomingComponent } from "./pages/event/upcoming.component";
import { AgmCoreModule } from "@agm/core";

import { SigninComponent } from "./accounts/signin/signin.component";
import { SharePageComponent } from "./shared/share-page/share-page.component";
import { SocialWidgetComponent } from "./shared/social-widget/social-widget.component";
import { ShareButtonsModule } from "ngx-sharebuttons";
import { BlogDetailsComponent } from "./pages/blog/blog-details/blog-details.component";

import { AlertComponent } from "./_directives/alert.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AlertService } from "./services/alert.service";
import { AuthenticationService } from "./services/authentication.service";
import { MainService } from "./services/main.service";
import { MediaService } from "./services/media.service";
import { EventDetailsComponent } from "./pages/event/event-details/event-details.component";
import { SafePipe } from "./shared/pipe/safe.pipe";
import { DOBDirective } from "./_directives/dob.directive";
import { TextOnlyDirective } from "./_directives/digit-onlye-text.directive";
import { DigitOnlyDirective } from "./_directives/digit-only.directive";
import { SpecialCharacterInputDirective } from "./_directives/special-character.directive";
import { BirthDate } from "./_directives/birth-date.directive";
import { WelcomeComponent } from "./template/welcome/welcome.component";
import { AlertMsgComponent } from "./template/alert-msg/alert-msg.component";
import { PanelMemberComponent } from "./pages/panel-member/panel-member.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { IcardComponent } from "./pages/profile/icard.component";
import { ChangePasswordComponent } from "./accounts/change-password/change-password.component";
import { SearchComponent } from "./pages/search/search.component";
import { TermsAndConditionComponent } from "./pages/terms-and-condition/terms-and-condition.component";
import { RecaptchaModule, ScriptLoaderService } from "angular-google-recaptcha";
import { PathLocationStrategy, LocationStrategy } from "@angular/common";
import { environment } from "../environments/environment";
import { Input } from "@angular/core/src/metadata/directives";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { VerifyMobileComponent } from "./accounts/verify-mobile/verify-mobile.component";
import { OwlModule } from "ngx-owl-carousel";
import { ReadMoreComponent } from "./read-more/read-more.component";
import { PaymentsComponent } from "./payments/payments.component";
import { AuditAuthorityComponent } from "./pages/audit-authority/audit-authority.component";
import { CertificationComponent } from "./pages/certification/certification.component";
import { WhatwedoComponent } from "./pages/whatwedo/whatwedo.component";
import { PatronsComponent } from "./pages/patrons/patrons.component";
import { PaidCertificateComponent } from "./pages/paid-certificate/paid-certificate.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { DisclaimerComponent } from "./pages/disclaimer/disclaimer.component";
// import { JwPaginationComponent } from "jw-angular-pagination";
import { TestimonialsComponent } from "./pages/testimonials/testimonials.component";
import { BottomComponent } from "./core/bottom/bottom/bottom.component";
import { PrivacyStandardsComponent } from "./pages/privacy-standards/privacy-standards.component";
import { NgxPaginationModule } from "ngx-pagination";
import { InputRestrictionDirective } from "./_directives/Input-restriction.directive";
import { AboutComponent } from "./pages/about/about.component";
import { TermsAndConditionAuditComponent } from "./pages/terms-and-condition-audit/terms-and-condition-audit.component";
import { TermsAndConditionVolunteerComponent } from "./pages/terms-and-condition-volunteer/terms-and-condition-volunteer.component";
import { RouterModule } from "@angular/router";
import { AboutHindiComponent } from "./pages/about-hindi/about-hindi.component";

const routes = [
  {
    path: "about-wavf",
    component: AboutComponent,
  },
];

let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    //provider: new FacebookLoginProvider("3207920672556064") - old
    provider: new FacebookLoginProvider("556445678099150"), // hosted on Rahul'fb account
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      "16745049071-hk53v0tba57ui5ro7vsvspijtk5r5o2q.apps.googleusercontent.com" // hosted on wavf.org@gmail.com
    ),
    //provider: new GoogleLoginProvider("16745049071-vmk8t50kk0flomlcn55stbetf2tbvkvp.apps.googleusercontent.com")
  },
]);
export function provideConfig() {
  return config;
}
export function metaFactory(translate: TranslateService): MetaLoader {
  return new MetaStaticLoader({
    callback: (key: string) => translate.get(key),
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: " - ",
    applicationName: "APP_NAME",
    defaults: {
      title: "DEFAULT_TITLE",
      description: "DEFAULT_DESC",
      "og:image":
        "https://upload.wikimedia.org/wikipedia/commons/f/f8/superraton.jpg",
      "og:type": "website",
      "og:locale": "en_US",
      "og:locale:alternate": "en_US,nl_NL,tr_TR",
    },
  });
}
@NgModule({
  declarations: [
    AppComponent,
    MenusComponent,
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    HomeComponent,
    FeaturesComponent,
    ServicesComponent,
    LoginComponent,
    ForumComponent,
    FaqComponent,
    AccredetationComponent,
    AffiliationComponent,
    VolunteerComponent,
    EventComponent,
    UpcomingComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    NewsGalleryComponent,
    BlogComponent,
    RecentComponent,
    ContactComponent,
    VoyeurismFreeComponent,
    OtherMaterialComponent,
    ResearchComponent,
    CaseStudyComponent,
    LawDraftComponent,
    WavfComponent,
    LawsComponent,
    TeamComponent,
    SocialLinkComponent,
    SigninComponent,
    SharePageComponent,
    SocialWidgetComponent,
    BlogDetailsComponent,
    AlertComponent,
    EventDetailsComponent,
    SafePipe,
    DOBDirective,
    TextOnlyDirective,
    DigitOnlyDirective,
    SpecialCharacterInputDirective,
    BirthDate,
    WelcomeComponent,
    AlertMsgComponent,
    PanelMemberComponent,
    ProfileComponent,
    IcardComponent,
    ChangePasswordComponent,
    SearchComponent,
    TermsAndConditionComponent,
    VerifyMobileComponent,
    ReadMoreComponent,
    PaymentsComponent,
    AuditAuthorityComponent,
    CertificationComponent,
    WhatwedoComponent,
    PatronsComponent,
    PaidCertificateComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    // JwPaginationComponent,
    TestimonialsComponent,
    BottomComponent,
    PrivacyStandardsComponent,
    InputRestrictionDirective,
    AboutComponent,
    TermsAndConditionAuditComponent,
    TermsAndConditionVolunteerComponent,
    AboutHindiComponent,
  ],
  imports: [
    BrowserModule,
    NgbPaginationModule,
    NgbAlertModule,
    AngularFontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SocialLoginModule,
    OwlModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,
    }),
    RecaptchaModule.forRoot({
      // siteKey: '6LdUu_cSAAAAAJT-SnxZm_EL_NwazPuCwgfb70Wo',
      siteKey: environment.siteKey,
    }),
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [TranslateService],
    }),
    ShareButtonsModule.forRoot(),
    Ng2OdometerModule.forRoot(),
    RouterModule.forRoot(routes, {
      anchorScrolling: "disabled",
    }),
  ],
  providers: [
    SignupService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    MainService,
    MediaService,
    ScriptLoaderService,
    Title,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy  },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestOptions, Request, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";

let headers: { "Content-Type": "application/x-www-form-urlencoded" };

@Injectable({
  providedIn: "root",
})
export class EventService {
  apiURL = environment.apiUrl;

  constructor(protected http: HttpClient) {}

  getAllUpcomingEventList(): Observable<any> {
    return this.http.get(`${this.apiURL}event/getAllUpcomingEventList`);
  }

  getEventDetailsById(activeEvent): Observable<any> {
    return this.http.post(
      `${this.apiURL}event/getEventDetailsById`,
      activeEvent,
      { headers }
    );
  }
  markAsInterestedForEvent(interes): Observable<any> {
    return this.http.post(
      `${this.apiURL}event/markAsInterestedForEvent`,
      interes,
      { headers }
    );
  }
  markAsUninterested(interes): Observable<any> {
    return this.http.post(`${this.apiURL}event/markAsUninterested`, interes, {
      headers,
    });
  }
  eventFeedback(feedback): Observable<any> {
    return this.http.post(`${this.apiURL}event/event_feedback`, feedback, {
      headers,
    });
  }
  checkUserInterestedForEvent(data): Observable<any> {
    return this.http.post(
      `${this.apiURL}event/checkUserInterestedForEvent`,
      data,
      { headers }
    );
  }
}

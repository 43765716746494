import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-laws',
  templateUrl: './laws.component.html',
  styleUrls: ['./laws.component.scss']
})
export class LawsComponent implements OnInit {
  constructor(private laws:UsersService) { }
  
  public data : any;
  @Input() itemLimit : any;
  @Input() divClass : any;

  ngOnInit() {
     this.data = this.laws.laws;
  }
 
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { MainService } from "../../services/main.service";
import { environment } from "../../../environments/environment";
class Law {
  constructor(public userComments: string = "", public userName: string = "") {}
}
@Component({
  selector: "app-law-draft",
  templateUrl: "./law-draft.component.html",
  styleUrls: ["./law-draft.component.scss"],
})
export class LawDraftComponent implements OnInit {
  constructor(
    private titleService: Title,
    private mainService: MainService,
    private toastr: ToastrService
  ) {}

  public url;
  public shareLoad;
  public lawComments;
  spinnerLoader = false;
  model: Law = new Law();
  @ViewChild("f") form: any;
  validCheck;
  status;

  public metaUrl = window.location.href;
  public metaTitle = "WAVF Law";
  public metaDescription =
    "WAVF or World Anti Voyeurism Forum is an organization against the offence of Voyeurism. Voyeurism is the practice of gaining sexual pleasure from watching or recording others when they are naked or engaged in sexual activity. WAVF has drafted a preventive and remedial law against Voyeurism. To read the online free PDF copy of this model law, click on the link below. You can also visit our website www.wavf.org.";
  public metaImage = environment.imageUrlShare + "law.png";
  public metaTags =
    "WAVF, AntiVoyeurism, modellaw, privacy, NGO, cybersecurity, peepingtom";

  ngOnInit() {
    this.titleService.setTitle("Law Draft");

    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.url = window.location.href;
    this.shareLoad = true;

    this.getComments();
    window.scroll(0, 0);
  }
  downloadCount() {
    let data;
    this.mainService.downloadLawCount(data).subscribe((response) => {});
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    const data = new HttpParams()
      .set("firstname", this.model.userName)
      .set("comment", this.model.userComments);
    const urlApi = "user/law_comment";
    this.mainService.postApi(urlApi, data).subscribe((response) => {
      this.status = response;
      if ((response = true)) {
        this.toastr.success(
          "Thank you for your comment! Your comment will be displayed after approval from WAVF",
          "",
          {
            positionClass: "toast-bottom-center",
          }
        );
        this.validCheck = false;
        this.form.reset();
      } else {
        this.toastr.error("Something went wrong.", "", {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
    });
  }

  getComments() {
    const urlApi = "user/get_approved_comment_for_law_book";
    let data;
    this.mainService.postApi(urlApi, data).subscribe((response) => {
      this.lawComments = response.law_comment_details;
    });
  }

  // FOR PAGE SHARE
  // public include = ['facebook','twitter','whatsapp']
  // public metaUrl = this.url;
  // public metaTitle = "World Anti Voyeurism Forum";
  // public metaDescription = "The World Anti-Voyeurism Forum (WAVF) is a Non-Governmental Organization registered in India and headquartered at Sangli, Maharashtra, India. We are a group of public-spirited members from various fields like Law, Engineering, Information and Technology, Cyber, Medical, etc working towards the protection, prohibition and redressal of the offence of Voyeurism and Violation of Privacy";

  // public metaTags = "Anti-Voyeurism, NGO, Law";
}

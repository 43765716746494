import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";

import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { MainService } from "../../services/main.service";
import { ToastrService } from "ngx-toastr";
class Password {
  constructor(
    public password: string = "",
    public cunfurmPassword: string = ""
  ) {}
}
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private mainService: MainService,
    private toastr: ToastrService
  ) {}
  @Output() dismissModal = new EventEmitter<string>();
  dismiss() {
    this.dismissModal.next();
    this.MsgAction = !this.MsgAction;
  }
  currentUser;
  spinnerLoader;
  MsgAction;
  Msg;
  error;
  status;
  validCheck = false;

  model: Password = new Password();
  @ViewChild("f") form: any;
  ngOnInit() {}

  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    if (this.model.password == this.model.cunfurmPassword) {
      this.getCurrentUser();
      this.spinnerLoader = true;
      const data = new HttpParams()
        .set("userId", this.currentUser.user_id)
        .set("password", this.model.password);
      const url = "profile/changePassword";
      this.mainService.postApi(url, data).subscribe((response: any) => {
        this.error = response.error;
        this.status = response.status;
        if (response.status == "true") {
          //this.Msg = "Your password successfully changed.";
          this.form.reset();
          this.toastr.success("Your password successfully changed.", "", {
            positionClass: "toast-bottom-center",
          });
          this.dismiss();
        } else {
          this.toastr.error("Something went wrong", this.error, {
            positionClass: "toast-bottom-center",
          });
        }
        this.spinnerLoader = false;
        this.MsgAction = true;
      });
    }
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: "https://wavf.org/api/",
  imageUrl: "https://wavf.org/admin/",
  imageUrlShare: "https://wavf.org/assets/images",
  imageUrl_Testimonial: "https://wavf.org/admin/gallary_image/",
  imgUserProfiles: "https://wavf.org/api/user_profile_images/",
  production: true,
  siteKey: "6LfRT6gUAAAAAM7NH0Oq_jxgT-yflRQYGWMAkFBR",
  //googleMapKey:'AIzaSyCjESYB_wBdydW949gB-6XWddO0JktZbMc'
  //siteKey: '6LeUjKAUAAAAADLPcoVvp-rU-T5KMw39JO7ACj_5',
  googleMapKey: "AIzaSyDdao8lfUUqxnmoX8D44MQD0thZRdRFdqY",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
import { ToastrService } from "ngx-toastr";
class Contact {
  constructor(
    public user_name: string = "",
    public user_email: string = "",
    public user_phone: string = "",
    public message_subject: string = "",
    public user_message: string = "",
    public myRecaptcha: string = ""
  ) {}
}

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  constructor(
    private mainService: MainService,
    private titleService: Title,
    private toastr: ToastrService
  ) {}
  lat: number = 16.8515193;
  lng: number = 74.5739589;
  status;
  Msg = null;
  public errorRequired = "is required.";
  public validCheck = false;
  public MsgAction = false;

  model: Contact = new Contact();
  @ViewChild("f") form: any;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Contact Us");
    window.scroll(0, 0);
  }
  myRecaptcha = false;

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.MsgAction = false;
    let data = new HttpParams()
      .set("user_name", this.model.user_name)
      .set("user_email", this.model.user_email)
      .set("user_phone", this.model.user_phone)
      .set("message_subject", this.model.message_subject)
      .set("user_message", this.model.user_message);

    if (this.form.valid) {
      const urlApi = "user/send_message";

      this.mainService.postApi(urlApi, data).subscribe((response: any) => {
        this.status = response;
        if (this.status.status === "true") {
          this.toastr.success("Message successfully sent.", "", {
            positionClass: "toast-bottom-center",
          });
          this.form.reset();
          this.myRecaptcha = true;
          this.validCheck = false;
        } else {
          this.toastr.error("Something went wrong.", this.status.error, {
            positionClass: "toast-bottom-center",
          });
        }
        this.MsgAction = true;
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { RequestOptions, Request, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };

@Injectable({
  providedIn: "root",
})
export class BlogService {
  constructor(private http: HttpClient) {}
  apiURL = environment.apiUrl;

  getBlog(): Observable<any> {
    return this.http.get(`${this.apiURL}blog/getAllActiveBlogList`);
    // .map(res => res);
  }
  getBlogDetailsById(activeBog): Observable<any> {
    return this.http.post(`${this.apiURL}blog/getBlogDetailsById`, activeBog, {
      headers,
    });
  }
  submitComments(data) {
    return this.http.post(`${this.apiURL}blog/commentToBlog`, data, {
      headers,
    });
  }
}

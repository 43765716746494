import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "../pages/home/home.component";
import { LoginComponent } from "../accounts/login/login.component";
import { SigninComponent } from "../accounts/signin/signin.component";

import { ForumComponent } from "../pages/forum/forum.component";
import { FaqComponent } from "../pages/faq/faq.component";
import { AccredetationComponent } from "../pages/accredetation/accredetation.component";
import { AffiliationComponent } from "../pages/affiliation/affiliation.component";
import { VolunteerComponent } from "../pages/volunteer/volunteer.component";
import { EventComponent } from "../pages/event/event.component";
import { PhotoGalleryComponent } from "../pages/photo-gallery/photo-gallery.component";
import { VideoGalleryComponent } from "../pages/video-gallery/video-gallery.component";
import { NewsGalleryComponent } from "../pages/news-gallery/news-gallery.component";
import { BlogComponent } from "../pages/blog/blog.component";
import { BlogDetailsComponent } from "../pages/blog/blog-details/blog-details.component";
import { ContactComponent } from "../pages/contact/contact.component";
import { VoyeurismFreeComponent } from "../pages/voyeurism-free/voyeurism-free.component";
import { OtherMaterialComponent } from "../pages/other-material/other-material.component";
import { ResearchComponent } from "../pages/research/research.component";
import { CaseStudyComponent } from "../pages/case-study/case-study.component";
import { LawDraftComponent } from "../pages/law-draft/law-draft.component";
import { EventDetailsComponent } from "../pages/event/event-details/event-details.component";
import { PanelMemberComponent } from "../pages/panel-member/panel-member.component";
import { ProfileComponent } from "../pages/profile/profile.component";
import { SearchComponent } from "../pages/search/search.component";
import { TermsAndConditionComponent } from "../pages/terms-and-condition/terms-and-condition.component";
import { PaymentsComponent } from "../payments/payments.component";
import { CertificationComponent } from "../pages/certification/certification.component";
import { AuditAuthorityComponent } from "../pages/audit-authority/audit-authority.component";
import { WhatwedoComponent } from "../pages/whatwedo/whatwedo.component";
import { PatronsComponent } from "../pages/patrons/patrons.component";
import { PaidCertificateComponent } from "../pages/paid-certificate/paid-certificate.component";
import { PrivacyPolicyComponent } from "../pages/privacy-policy/privacy-policy.component";
import { DisclaimerComponent } from "../pages/disclaimer/disclaimer.component";
import { TestimonialsComponent } from "../pages/testimonials/testimonials.component";
import { PrivacyStandardsComponent } from "../pages/privacy-standards/privacy-standards.component";
import { AboutComponent } from "../pages/about/about.component";
import { TermsAndConditionAuditComponent } from "../pages/terms-and-condition-audit/terms-and-condition-audit.component";
import { TermsAndConditionVolunteerComponent } from "../pages/terms-and-condition-volunteer/terms-and-condition-volunteer.component";
import { AboutHindiComponent } from "../pages/about-hindi/about-hindi.component";

export const APP_ROUTS: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "welcome", component: HomeComponent },
  { path: "profile", component: ProfileComponent },
  { path: "forum", component: ForumComponent },
  { path: "faq", component: FaqComponent },
  { path: "lawdraft", component: LawDraftComponent },
  { path: "case-study", component: CaseStudyComponent },
  { path: "book", component: ResearchComponent },
  { path: "other-material", component: OtherMaterialComponent },
  { path: "getcertified", component: AccredetationComponent },
  { path: "affilation", component: AffiliationComponent },
  { path: "volunteer", component: VolunteerComponent },
  { path: "panel-member", component: PanelMemberComponent },
  { path: "voyeurism-free", component: VoyeurismFreeComponent },
  { path: "events", component: EventComponent },
  { path: "events-details/:id", component: EventDetailsComponent },
  { path: "photo-gallery", component: PhotoGalleryComponent },
  { path: "video-gallery", component: VideoGalleryComponent },
  { path: "in-news", component: NewsGalleryComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog-details/:id", component: BlogDetailsComponent },
  { path: "contact", component: ContactComponent },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SigninComponent },
  { path: "search", component: SearchComponent },
  { path: "search/:id", component: SearchComponent },
  { path: "terms-and-condition", component: TermsAndConditionComponent },
  { path: "donation", component: PaymentsComponent },
  { path: "certification", component: CertificationComponent },
  { path: "auditauthority", component: AuditAuthorityComponent },
  { path: "whatwedo", component: WhatwedoComponent },
  { path: "patrons", component: PatronsComponent },
  { path: "paid-certificate", component: PaidCertificateComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "disclaimer", component: DisclaimerComponent },
  { path: "testimonials", component: TestimonialsComponent },
  { path: "privacy-standards", component: PrivacyStandardsComponent },
  { path: "about-wavf", component: AboutComponent },
  { path: "about-wavf-hindi", component: AboutHindiComponent },
  {
    path: "terms-and-condition-audit",
    component: TermsAndConditionAuditComponent,
  },
  {
    path: "terms-and-condition-volunteer",
    component: TermsAndConditionVolunteerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTS, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

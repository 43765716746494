import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { SearchService } from "../../services/search.service";
class instaSearch {
  constructor(
    public search_data: string = "",
    public search_filter: String = ""
  ) {}
}
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  constructor(
    private searchService: SearchService,
    private titleService: Title,
    private route: ActivatedRoute
  ) {}
  public activeBlog;
  public blog_image = "blog_image/";
  public imageUrl = environment.imageUrl;

  public Msg;
  public MsgAction = false;
  public spinnerLoader = false;
  public validCheck = false;

  public event_search;
  public affication_search;
  public accredation_search;
  public user_search;
  public blog_search;
  public advance_search;

  public affication_searchLenght;
  public accredation_searchLenght;
  public user_searchLenght;
  public blog_searchLenght;
  public event_searchLenght;

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Search");
    this.advance_search = this.route.snapshot.paramMap.get("id");
    window.scroll(0, 0);
  }

  model: instaSearch = new instaSearch();
  @ViewChild("f") form: any;

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }

    this.spinnerLoader = true;
    const data = new HttpParams()
      .set("search_data", this.model.search_data)
      .set("advance_search", this.advance_search);
    this.searchService
      .insta_search(data)

      .subscribe((response) => {
        if (response.status == "true") {
          this.MsgAction = false;
          this.form.reset();
          this.event_search = response.event_search;
          this.event_searchLenght = response.event_search.length;
          this.affication_search = response.affication_search;
          this.affication_searchLenght = response.affication_search.length;
          this.accredation_search = response.accredation_search;
          this.accredation_searchLenght = response.accredation_search.length;
          this.user_search = response.user_search;
          this.user_searchLenght = response.user_search.length;
          this.blog_search = response.blog_search;
          this.blog_searchLenght = response.blog_search.length;
        } else {
          this.Msg = response.data;
          this.MsgAction = true;
        }
        this.spinnerLoader = false;
      });
  }
}

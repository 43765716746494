import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { MediaService } from "../../services/media.service";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";
import * as $ from "jquery";
import "magnific-popup";
@Component({
  selector: "app-news-gallery",
  templateUrl: "./news-gallery.component.html",
  styleUrls: ["./news-gallery.component.scss"],
})
export class NewsGalleryComponent implements OnInit {
  constructor(
    private mediaService: MediaService,
    private titleService: Title
  ) {}
  image = "/news_image/";
  public imageUrl = environment.imageUrl;
  public news;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("WAVF in news");
    this.getAllNewsList();
  }

  @ViewChild("imgGallary") public imgElement: ElementRef;
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.imgElement.nativeElement) {
        $(this.imgElement.nativeElement).magnificPopup({
          delegate: "a",
          gallery: {
            enabled: true,
          },
          type: "image",
        });
      }
    }, 2000);
  }

  getAllNewsList() {
    this.mediaService.getAllNewsList().subscribe((response) => {
      this.news = response.data;
    });
  }
}

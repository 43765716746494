import { Component, OnInit, HostListener } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public constructor(
    private titleService: Title,
    private mainService: MainService
  ) {}

  showCaption = true;
  counterBG = "assets/images/background/10.jpg";
  gridBG = "assets/images/background/gridBackground.jpg";
  count;
  digital;
  public certifiedPremises: number;
  public volunteers: number;

  public config = {
    animation: "count",
    format: "d",
    theme: "",
    value: 1,
  };

  ngOnInit(): void {
    this.titleService.setTitle("WAVF : World Anti Voyeurism Forum");
    const showNavigationArrows = false;
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu", "theme_menu_1");

    // this.loadScript(" odometer.js");
    // setTimeout(() => this.loadScript("./odometer.js"), 10000); // 2500

    window.scrollTo(0, 0);
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {
    if (pageYOffset > 2700) {
      this.gettotalVolunteers();
      this.getcertifiedPremises();
    }
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  gettotalVolunteers() {
    let data;
    this.mainService.getTotalVolunteers(data).subscribe((response) => {
      this.volunteers = response.total_volunteers;
    });
  }

  getcertifiedPremises() {
    let data;
    this.mainService.getCertifiedPrimises(data).subscribe((response) => {
      this.certifiedPremises = response.totalCertifiedPremises;
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { BlogService } from "../../services/blog/blog.service";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent implements OnInit {
  items = [];
  pageOfItems: Array<any>;
  p: number = 1;
  collection: any[];
  constructor(private blogService: BlogService, private titleService: Title) {}
  public activeBlog;
  blog_image = "blog_image/";
  public imageUrl = environment.imageUrl;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");

    this.titleService.setTitle("Blogs");
    this.blogService.getBlog().subscribe((response) => {
      this.collection = response.data;
    });
    window.scroll(0, 0);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getBlog() {
    this.blogService.getBlog().subscribe((response) => {
      this.activeBlog = response.data;
    });
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";

import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { MainService } from "../../services/main.service";
import { SignupService } from "../../services/signup/signup.service";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

class Volunteer {
  constructor(
    public firstname: string = "",
    public lastname: string = "",
    public email: string = "",
    public mobile: string = "",
    public userrole: string = "2",
    public volunteering_mode: string = null,
    public address: string = "",
    public country: string = null,
    public state: string = null,
    public city: string = null,
    public pincode: string = "",
    public dateofbirth: string = "",
    public gender: string = null,
    public password: string = "",
    public cunfurmPassword: string = "",
    public prifile_photo: string = "",
    public website_url: string = "http://wavf.org/",
    public interestedAsVolunteer: string = null,
    public interestedAsPanelMember: string = "0",
    public experience_skills: string = "",
    public occupation: string = ""
  ) {}
}

@Component({
  selector: "app-volunteer",
  templateUrl: "./volunteer.component.html",
  styleUrls: ["./volunteer.component.scss"],
})
export class VolunteerComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  date;
  constructor(
    private mainService: MainService,
    private signupService: SignupService,
    private titleService: Title,
    private toastr: ToastrService
  ) {
    this.minDate = this.mainService.maxAge;
    this.maxDate = this.mainService.minAge;
    this.date = new Date().toISOString().slice(0, 10);
  }

  model: Volunteer = new Volunteer();
  @ViewChild("f") form: any;

  public errorRequired = "is required.";
  public error;
  public countrys;
  public states;
  public citys;
  public citysStatus = false;
  public setPassword = false;
  public volunteer;
  public validCheck = false;
  public myRecaptcha = false;

  businessTypes;
  file;

  public imagePath;
  imgURL: any;
  public message: string;
  extention;
  MsgAction;
  spinnerLoader = false;
  Msg;
  status;

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Become a Volunteer");
    this.getAllCountry();
    this.getAllEntity();
    this.getAllVolunteerOptions();
    window.scroll(0, 0);
  }

  getAllCountry() {
    this.mainService.getAllCountry().subscribe((response) => {
      this.countrys = response.data;
    });
  }

  getAllEntity() {
    this.mainService.getAllEntity().subscribe((response) => {
      this.businessTypes = response.data;
    });
  }
  getAllVolunteerOptions() {
    this.mainService.getAllVolunteerOptions().subscribe((response) => {
      this.volunteer = response.data;
    });
  }

  getAllStatesByCountryId(countryId) {
    let data = new HttpParams().set("countryId", countryId);
    this.mainService
      .getAllStatesByCountryId(data)
      .subscribe((response: any) => {
        this.states = response.data;
      });
  }

  getAllCityByStateId(stateId) {
    let data = new HttpParams().set("stateId", stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;
      this.citysStatus = response.status;
    });
  }
  upload(files) {
    if (files.length === 0) return;

    const formData = new FormData();

    for (let file of files) formData.append(file.name, file);
  }
  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    let ext = files[0].name.split(".").pop();
    this.extention = "." + ext;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // POST WITH API
  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    const data = new HttpParams()
      .set("firstname", this.model.firstname)
      .set("lastname", this.model.lastname)
      .set("email", this.model.email)
      .set("mobile", this.model.mobile)
      .set("userrole", this.model.userrole)
      .set("address", this.model.address)
      .set("country", this.model.country)
      .set("state", this.model.state)
      .set("city", this.model.city)
      .set("pincode", this.model.pincode)
      .set("volunteering_mode", this.model.volunteering_mode)
      .set("dateofbirth", this.model.dateofbirth)
      .set("gender", this.model.gender)
      .set("password", this.model.password)
      .set("website_url", this.model.website_url)
      .set("interestedAsVolunteer", this.model.interestedAsVolunteer)
      .set("interestedAsPanelMember", this.model.interestedAsPanelMember)
      .set("experience_skills", this.model.experience_skills)
      .set("occupation", this.model.occupation)
      .set("userProfileImage", this.imgURL);
    this.signupService.post(data).subscribe((response: any) => {
      this.error = response.error;
      this.status = response.status;
      if (response.status == "true") {
        this.toastr.success(
          "Done. Please check your email for account activation.",
          "",
          { positionClass: "toast-bottom-center" }
        );
        this.form.reset();
        this.myRecaptcha = true;
        this.imgURL = null;
      } else {
        this.toastr.error(
          "Mobile Number or Email Id is already registered",
          "",
          { positionClass: "toast-bottom-center" }
        );
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { WindowRefService } from "../services/window-ref.service";
import { Title } from "@angular/platform-browser";
import { MainService } from "../services/main.service";
import { ToastrService } from "ngx-toastr";
import { HttpParams } from "@angular/common/http";
import swal from "sweetalert2";

class Payment {
  constructor(
    public donation_id: number = 0,
    public user_name: string = "",
    public user_email: string = "",
    public user_phone: string = "",
    public donation_currency: string = "",
    public donation_amount: string = "",
    public user_message: string = "",
    public myRecaptcha: string = "",
    public taxExemption_checkbox: boolean = false,
    public user_pan: any = "",
    public user_address: any = ""
  ) { }
}

@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
  providers: [WindowRefService],
})
export class PaymentsComponent implements OnInit {
  taxExemption_checkbox
  constructor(
    private winRef: WindowRefService,
    private mainService: MainService,
    private titleService: Title,
    private toastr: ToastrService
  ) { }
  status;
  Msg = null;
  public errorRequired = "is required.";
  public validCheck = false;
  public MsgAction = false;
  public order_id = "";

  model: Payment = new Payment();
  @ViewChild("f") form: any;

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");

    this.titleService.setTitle("Donation");
    this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }
  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement("script");
    script.innerHTML = "";
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  myRecaptcha = false;

  createRzpayOrder() {
    if (this.form.invalid) {
      if (this.model.taxExemption_checkbox) {    
        if (this.model.user_pan == "" || this.model.user_address == "") {
          this.toastr.error("Please provide PAN and Address.", "", {
            positionClass: "toast-bottom-center",
          });
          return;
        }
      }
      this.validCheck = true;
      return;
    }
    
    

    let donationId = new Date().getTime().toString();
    let donationData = new HttpParams()
      .set("order_id", donationId)
      .set("donar_name", this.model.user_name)
      .set("email", this.model.user_email)
      .set("mobile", this.model.user_phone)
      .set("convert_country_code", this.model.donation_currency)
      .set("donation_amount", this.model.donation_amount)
      // .set("message_from_doner", this.model.user_message);
      .set("user_pan", this.model.user_pan)
      .set("user_address", this.model.user_address)

    if (this.form.valid) {
      const apiUrl = "user/donate";
      this.mainService
        .sendDonationInfo(donationData)
        .subscribe((response: any) => {
          this.status = response;
          this.order_id = response.order_id;
          if (this.status.status === "true") {
            this.payWithRazor(response);
          } else {
            this.toastr.error("Donation failed.", this.status.error, {
              positionClass: "toast-bottom-center",
            });
          }
          this.MsgAction = true;
        });
    }
  }

  payWithRazor(donationData) {
    const options: any = {
      key: "rzp_live_XFkwEOYIFDJNFl",
      amount: parseInt(donationData.converted_donation_amount), // amount should be in paise format to display Rs 1255 without decimal point
      currency: "INR",
      name: donationData.donar_name, // company name or product name
      description: "", // product description
      image: "../../../assets/assets/images/logo/logo.png", // company logo or product image
      order_id: this.order_id, // order_id created by you in backend

      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },

      notes: {
        // include notes if any
      },
      prefill: {
        email: this.model.user_email,
        contact: this.model.user_phone,
      },
      theme: {
        color: "#0c238a",
      },
    };

    options.handler = (response0, error) => {
      options.response = response0;

      // call your backend api to verify payment signature & capture transaction
      if (
        response0.razorpay_payment_id !== "" ||
        response0.razorpay_payment_id !== null
      ) {
        let donationStatus = new HttpParams()
          .set("razorpay_payment_id", response0.razorpay_payment_id)
          .set("wavf_donation_id", donationData.wavf_donation_id)
          .set("order_id", this.order_id);

        this.mainService
          .getDonationStatus(donationStatus)
          .subscribe((response: any) => {
            // if (response.status === "true") {
            swal({

              html:
                "<div style=border:2px>" +
                "<div style=float:right><img src=assets/images/certificate_header.jpg /></div>" + "<br><br><br><br><br><br><br>" +
                "<div><center><h2> Thank You </h2></center></div>" + "<br>" +
                "<div> <h5 style=line-height:22px>Thank you for your donation. We are greatful for your assistance in our Mission Privacy. We look forward to your continuous support! </h5></div>" +
                "" +
                "<br><br> <center><img src=assets/images/certificate_logo.jpg /></center>" +
                "" +
                "<br><center><b>www.wavf.org</b></center> </div>",
              // type: "info",
              showCloseButton: true,
              timer: 8000,
              width: 600,
            }).then(function(){
              window.location.href = 'www.wavf.org/home'
            })
            // this.toastr.success("Thank you for donation.", "", {
            //   positionClass: "toast-bottom-center",
            // });
            this.form.reset();
            this.myRecaptcha = true;
            this.validCheck = false;
            // } else {
            //   this.toastr.error("Something went wrong.", this.status.error, {
            //     positionClass: "toast-bottom-center",
            //   });
            // }
          });
      }
    };
    options.modal.ondismiss = () => {
      // handle the case when user closes the form while transaction is in progress

      let donationStatus = new HttpParams()
        .set("razorpay_payment_id", "PAYMENT_CANCELLED")
        .set("wavf_donation_id", donationData.wavf_donation_id)
        .set("order_id", this.order_id);

      this.mainService
        .getDonationStatus(donationStatus)
        .subscribe((response: any) => {
          if (response.status === "false") {
            this.toastr.error("Something went wrong. Please try again.", "", {
              positionClass: "toast-bottom-center",
            });
            this.form.reset();
            this.myRecaptcha = true;
            this.validCheck = false;
          } else {
            this.toastr.error("Something went wrong.", this.status.error, {
              positionClass: "toast-bottom-center",
            });
          }
        });
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  taxExemption() {
      this.model.taxExemption_checkbox = !this.model.taxExemption_checkbox;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-widget',
  templateUrl: './social-widget.component.html',
  styleUrls: ['./social-widget.component.scss']
})
export class SocialWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

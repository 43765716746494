import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ScriptLoaderService } from "angular-google-recaptcha";
import { HttpParams } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
import { ToastrService } from "ngx-toastr";

class Accredetation {
  constructor(
    public organisation: string = null,
    public businessType: string = null,
    public address: string = "",
    public country: string = null,
    public state: string = null,
    public city: string = null,
    public pincode: string = "",
    public mobileNumber: string = "",
    public email: string = "",
    public licence_copy: string = "",

    public accredetationType: string = "",
    public accredationTypeSelf: any = true,
    public accredationTypeWavf: any = false,
    public accredationTypePolice: any = false,
    public policeVerifiedCertificate: boolean = false,
    public pledge_checkbox: any = false
  ) {}
}

@Component({
  selector: "app-paid-certificate",
  templateUrl: "./paid-certificate.component.html",
  styleUrls: ["./paid-certificate.component.scss"],
})
export class PaidCertificateComponent implements OnInit {
  public imagePath;
  public imagePath2;
  imgURL: any;
  any;
  public message: string;
  extention;
  constructor(
    private mainService: MainService,
    private ScriptLoaderService: ScriptLoaderService,
    private ElementRef: ElementRef,
    private titleService: Title,
    private toastr: ToastrService
  ) {}

  public businessTypes;
  public myRecaptcha;
  model: Accredetation = new Accredetation();
  @ViewChild("f") form: any;
  @ViewChild("myRecaptcha1") myRecaptcha1: any;
  public errorRequired = "is missing";
  file;
  filePolice;
  MsgAction = false;
  Msg: any;
  countrys;
  status;
  states;
  citys;
  validCheck;
  spinnerLoader = false;
  citysStatus = false;

  ngOnInit() {
    this.titleService.setTitle("Paid Certification ");
    this.businessTypes;
    this.getAllCountry();
    this.getAllEntity();
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
  }

  getAllCountry() {
    this.mainService.getAllCountry().subscribe((response) => {
      this.countrys = response.data;
    });
  }

  getAllEntity() {
    this.mainService.getAllEntity().subscribe((response) => {
      this.businessTypes = response.data;
    });
  }

  getAllStatesByCountryId(countryId) {
    let data = new HttpParams().set("countryId", countryId);
    this.mainService
      .getAllStatesByCountryId(data)
      .subscribe((response: any) => {
        this.states = response.data;
      });
  }

  getAllCityByStateId(stateId) {
    let data = new HttpParams().set("stateId", stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;
      this.citysStatus = response.status;
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }

    this.spinnerLoader = true;
    let data = new HttpParams()
      .set("organisation", this.model.organisation)
      .set("business_entity", this.model.businessType)
      .set("address", this.model.address)
      .set("country", this.model.country)
      .set("state", this.model.state)
      .set("city", this.model.city)
      .set("zipcode", this.model.pincode)
      .set("contactno", this.model.mobileNumber)
      .set("email", this.model.email);

    const urlApi = "accredation/createPaideAccredation";
    this.mainService.postApi(urlApi, data).subscribe((response: any) => {
      this.status = response.status;
      const error = response.error;
      if (response.status == "true") {
        this.toastr.success("Thank you. We will contact you soon", "", {
          positionClass: "toast-bottom-center",
        });
        this.form.reset();
        this.model.pledge_checkbox = false;
        this.imgURL = null;
        this.myRecaptcha = true;
      } else {
        this.toastr.error("Something went wrong.", error, {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

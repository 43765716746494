import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };

@Injectable()
export class SignupService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  postSocial(url, data): Observable<any> {
    return this.http.post(this.apiURL + url, data, { headers });
  }

  post(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/signup`, data, { headers });
  }
}

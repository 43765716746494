import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-privacy-standards",
  templateUrl: "./privacy-standards.component.html",
  styleUrls: ["./privacy-standards.component.scss"],
})
export class PrivacyStandardsComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}

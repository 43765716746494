import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "angular4-social-login";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };
@Injectable()
export class AuthenticationService {
  private subject = new Subject<any>();
  apiURL = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  login(data) {
    return this.http.post<any>(
      `${this.apiURL}login/checkloginCredentials`,
      data,
      { headers }
    );
  }

  async logout() {
    localStorage.removeItem("currentUser");

    await this.authService
      .signOut()
      .then((res) => {
        // localStorage.removeItem("currentUser");
        // window.location.reload();
      })
      .catch((res) => {
        console.error("Error : ", res);
      });
    this.router.navigate(["/"]);
  }
  loginPopup(event) {
    this.subject.next({ text: event });
  }
  getevent(): Observable<any> {
    return this.subject.asObservable();
  }
  forgotPassword(data) {
    return this.http.post<any>(`${this.apiURL}User/forget_password`, data, {
      headers,
    });
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms-and-condition-audit",
  templateUrl: "./terms-and-condition-audit.component.html",
  styleUrls: ["./terms-and-condition-audit.component.scss"],
})
export class TermsAndConditionAuditComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    window.scroll(0, 0);
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-terms-and-condition-volunteer",
  templateUrl: "./terms-and-condition-volunteer.component.html",
  styleUrls: ["./terms-and-condition-volunteer.component.scss"],
})
export class TermsAndConditionVolunteerComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}

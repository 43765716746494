import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NgbAccordionConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-case-study",
  templateUrl: "./case-study.component.html",
  styleUrls: ["./case-study.component.scss"],
  providers: [NgbAccordionConfig],
})
export class CaseStudyComponent implements OnInit {
  constructor(config: NgbAccordionConfig, private titleService: Title) {
    // customize default values of accordions used by this component tree

    config.closeOthers = true;
    config.type = "info";
  }
  public isCollapsed = false;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");

    this.titleService.setTitle("WAVF : Frequently asked quetions");
    this.casestudys;
  }

  public casestudys = [
    {
      title: "Court Cases of Voyeurism",
      content: ` As many of the people are unaware of the crime like voyeurism there are fewer chances to get cases occurred on the voyeurism. Some of the cases'
    where voyeurism was recognized and conviction had taken place are as follows. Revenge porn or revenge pornography is the distribution of
    sexually explicit images or video of individuals without their consent. The sexually explicit images or video may be made by a partner of an
    intimate relationship or any other private act of any of the partners with the knowledge and consent of the other, or it may be made without his or
    her knowledge. The possession of the material may be used by the perpetrators to blackmail the subjects into performing other sex acts, to
    coerce them into continuing the relationship, or to punish them for ending the relationship. The term "revenge porn" generally refers to the
    uploading of this sexually explicit material to humiliate and intimidate the subject, who has broken off the relationship. In many sexual cases be
    it voyeurism, revenge porn, or even rape, majority of women as victims and men as offender. If any men goes through these offences, is not identified
    by law because sec 354 (c) is interpreted as “any man… to fine” so the presumption is now quite clear that the Indian law considers men as only
    offender but in reality even men go victimize to sexual violence but they are not revealed because of the fear of society and this is not even
    considered by Indian law. A PIL has been filed by Rishi Malhotra, (a Supreme Court lawyer who has filed several other successful PILs) before
    the Supreme Court for provision to punish women too for offences such as rape, sexual harassment, stalking and voyeurism, saying men also are
    victims.    <br /><br /> 
    <b>State vs. Manish Gupta on 3 December, 2013  </b> -    In this case the prosecutrix was working in Max Life Insurance Company  as an agent and advisor. Manish Gupta, the accused was serving there as
     a Manager. He began making advances at her. He also told her that he  would marry her and that they would live together. One day on the month
      of March 2013 the accused took her in a hotel at Dharamvilla where they stayed in a room for about five hours. On obtaining her trust he made
      her to put off her clothes and took her photographs in nude condition and assured her that he had taken these photographs only for the purpose
      of seeing her. After assuring her that he would marry her made physical relations with her. The accused who is already married have a daughter.
      They are settled in Delhi. The accused also gave false impression to the prosecutrix that he had already taken divorce by mutual consent from his
      wife Pooja. When the prosecutrix’s family members opposed their relationship the accused threatened that he would upload her nude photographs on net. She told him not to upload the photographs as it may
      spoil her reputation and even her family’s. She demanded to return back the photographs but the accused refused it. He even threatened to kill
      them. Later the prosecutrix was taken to hospital, where the Doctor observed about torn of her hymen in the MLC. The accused was also taken
      to hospital where his MLC was prepared. It was noted that there was nothing to suggest that the patient was not capable of performing sexual
      act. In this case the court charged accused under sec 354 C with respective to other sections. Here the accused is punished before the
      commission of crime. Here probability of misusing the materials in future has been curbed.
      
    <br /><br/>
    <b>Delhi District Court <br />State vs. Rahul @ Mayank on 29 November,
      2013</b> -
     
      This is another example of voyeurism case where a man who disclosed his
      name as Rahul called prosecutrix saying he liked her and he had seen her
      near a school about six months ago and since then he had been securing
      her details. Thereafter he asked her to meet him near a mall but she
      refused. The accused had been persistently pressurizing her to meet him.
      He threatened to commit suicide and to make her responsible for that
      when she told him that she would tell all these activities of the
      accused to her family members and also would make complaint to the
      police. One day when she arrived at a metro station for going to her
      institute, the accused made a call to her and told her to come
      immediately to another metro station and if she fails to do so he would
      commit suicide. Later in the said spot both met. The accused lured her
      and took her to a guest house where he expressed his love towards her
      and also forced her to make physical relations with her. When she
      refused to o so, he threatened her saying he will commit suicide there.
      Despite her denial he made physical relations with her and also took
      photos and prepared a video when she was naked. Thereafter he took her
      in a room in a restaurant where he threatened her that he would upload
      all her nude photos and videos on internet if she tells the incident to
      others. He also warned her that whenever he would call her, she would
      have to come. She was scared and returned to her house. The accused
      continued to call her and continued to send message to her but she did
      not reply as she was mentally disturbed. On 16.04.2013, she narrated all
      the incidents to her family members and on 17.04.2013, she and her
      family members arrived at PS and lodged her report reiterating her
      allegations that accused to whom she was unknown earlier, committed
      sexual intercourse with her forcibly against her will and without her
      consent. On the basis of her report FIR No. 299/13 at PS Shakarpur under
      Section 376/384/506 IPC was recorded. Prosecutrix was sent to Lal
      Bahadur Shastri Hospital where MLC was prepared and her internal
      examination was conducted. Doctor observed that her hymen was found
      torn. The accused was arrested on 22.04.2013 and his arrest memo and
      personal search memo were prepared. He was interrogated and his
      disclosure statement was recorded. He was also taken to Lal Bahadur
      Shastri hospital and his MLC was also prepared. Doctor opined that there
      was nothing to suggest that patient was not capable to perform sexual
      intercourse. Prosecutrix was produced before Ld. Metropolitan Magistrate
      on 243.04.2013 and her statement under Section 164 of the Code of
      Criminal Procedure, herein after referred to as the Code, was recorded
      in which she reiterated all the averments made in the report to the
      police. The IO also inspected the place of occurrence and prepared the
      site plan. All the exhibits obtained by the doctor were handed over to
      the police official who took the prosecutrix and the accused for their
      medical examination was taken into possession vide various seizure memos
      and were sent to FSL. The IO also obtained documents from the concerned
      guest house and seized the same. After recording of statement of various
      witnesses under Section 161 of the Code and on completion of
      investigation filed a charge sheet against the accused for his trial for
      the offences punishable u/s 376/384/506/354 (C)/354 (D) IPC. She and
      accused were in conversation for a considerable long time. Once the
      accused called her on 15.4.2013 and he took her to Eastinn guest house
      where she was beaten, threatened and terrorized. It was told to her that
      there was nobody to hear her and that he would tear off her clothes and
      then disclosed the entire incident to her father. By making a telephone
      call he asked his uncle (chacha) to come. She was scared thinking that
      so many people would come and misbehave with her. In that state of her
      mental status accused Rahul committed sex with her five times and also
      prepared video and threatened her to put the video on net if she tells
      the incident to others. Later on he was taken to hukka bar type
      restaurant and the accused threatened her that he would commit suicide
      and make her responsible by mentioning her name in the suicide note. He
      also warned her to make him telephone calls after every two hours. On
      considering the rival contentions of Ld. Defense Counsel and Ld.
      Substitute Additional Public Prosecutor, I find that there is sufficient
      material on record in the form of reports, MLCs and statement recorded
      under Section 164 and 161 of the Code, etc. which raise a grave
      suspicion against the accused that he might have committed the offence
      of rape punishable u/s 376 IPC, offence of criminal intimidation
      punishable under Section 506 IPC, offence of voyeurism punishable under
      Section 354(C) IPC and offence of staking punishable under Section 354 D
      IPC. Accordingly charge against accused for the offences punishable
      under Section 376/506/354 C/354DIPC are framed. There are certain cases
      where the court has given bail to the accused in voyeurism if it’s their
      first conviction and if the accused is convicted for the same offence
      second time then it is non-bailable offence. These are some of the cases
      where bail is granted.
      
    <br /> <br/>
    <b>Madhya Pradesh High Court <br />
      Chintu @ Arvindra Sen vs. The State Of Madhya Pradesh on 9December,
      2016  </b> - 
     
      The allegation against the applicant is that the applicant was in the
      habit of voyeurism whereby he used to observe women and also used to
      record their images in his mobile camera without their consent and
      knowledge on account of which the above said case has been registered
      against him. Learned counsel for the State has submitted that the mobile
      recovered from the applicant has photographs of women in denuded state
      which were taken without their knowledge and consent. Learned counsel
      for the applicant has submitted that the offence punishable under
      Section 354-C and 509 of the I.P.C. are bailable and for the offence
      under Section 66-C and 67 of the Information and Technology Act the
      maximum punishment is of three years imprisonment. Be that as it may,
      looking to the facts and circumstances of the case and the fact that the
      applicant has been in judicial custody since 17.05.2016 and that the
      charge sheet has been filed, I am inclined to allow the instant
      application and direct that the applicant - Chintu @ Arvind Sen be
      enlarged on bail upon his furnishing personal bond in the sum of
      Rs.50,000/- (Rupees Fifty Thousand Only) with one solvent surety in the
      like amount to the satisfaction of the Trial Court. Accordingly, the
      application is allowed. Cc as per rules.
      
   <br><br>
    <b>Vishal Kushwah vs. The State Of Madhya Pradesh on 9 September, 2015
      </b> - 
     
      Learned counsel for the rival parties is heard. The applicant has filed
      this first application u/S 439,Cr.P.C. for grant of bail. The applicant
      has been arrested by Police Station Janakganj, District Gwalior in
      connection with Crime No.298/2015 registered in relation to the offences
      punishable u/Ss. 354(C), 384, 506 and 294 of IPC. Learned Public
      Prosecutor for the State opposed the application and prayed for its
      rejection by contending that on the basis of the allegations and the
      material available on record, no case for grant of bail is made out. The
      applicant is in custody since 10.7.2015. Investigation is over by filing
      of charge-sheet. The offence of voyeurism punishable u/S. 354 C of IPC
      is alleged. The offence is the first offence of its kind against the
      applicant and therefore the IPC prescribes maximum penalty of three
      years and therefore offence is bailable and trial able by Magistrate.
      The trial is not likely to conclude in the near future and that
      prolonged pre- trial detention being an anathema to the concept of
      liberty and the material placed on record does not disclose the
      applicant fleeing from justice and since the investigation is over and
      looking to the gravity of offence the applicant is entitled for bail.
      Vishal Kushwaha vs. State of M.P. M.Cr.C. No.8753/2015 In view of the
      above, this Court is inclined to extend the benefit of bail to the
      applicant, but with certain stringent conditions in view of criminal
      antecedents. Accordingly, without expressing any opinion on merits of
      the case, this application is allowed and it is directed that the
      applicant be released on bail on furnishing a personal bond in the sum
      of Rs.50,000/- (Rs. Fifty Thousand only) with two solvent sureties of
      the same amount to the satisfaction of the concerned Trial Court. This
      order will remain operative subject to compliance of the following
      conditions by the applicant:- 1. The applicant will comply with all the
      terms and conditions of the bond executed by him; 2. The applicant will
      cooperate in the investigation/trial, as the case may be; 3. The
      applicant will not indulge himself in extending inducement, threat or
      promise to any person acquainted with the facts of the case so as to
      dissuade him/her from disclosing such facts to the Court or to the
      Police Officer, as the case may be; 4. The applicant shall not commit an
      offence similar to the offence of which he is accused; 5. The applicant
      will not seek unnecessary adjournments during the trial; and 6. The
      applicant will not leave India without previous permission of the trial
      Court/Investigating Officer, as the case may be. 7. The applicant shall
      mark his attendance before the trial Vishal Kushwaha vs. State of M.P.
      M.Cr.C. No.8753/2015 court once every fortnight. A copy of this order is
      sent to the Court concerned for compliance. C.c. as per rules. (Sheel
      Nagu) Judge ar
      
    <br /><br/>
    <b>Ramaji @ Ramachandra vs. The State Of Karnataka on 10 October, 2013  </b> - 
     
      The petitioner is accused of serious offences punishable under the
      various provisions of the Indian Penal Code, 1860, including Section
      354C, which is a newly incorporated provision relating to voyeurism.
      However, apart from the statement of the alleged victim, there is no
      material produced to indicate that he had indulged in any such
      activities. Insofar as other offences are concerned, they are
      exclusively trial able by the Court of Sessions and the maximum
      punishment that can be imposed, even if found guilty, does not exceed
      three years. Therefore, the prosecution will have to establish its case
      at the trial. Therefore, the petitioner is entitled to be enlarged on
      bail. The petition is allowed and the petitioner shall be enlarged on
      bail on his executing a personal bond in a sum of Rs.20,000/- with a
      solvent surety for a like sum to the satisfaction of the court below.
      The petitioner shall not leave the jurisdiction of the Court below
      without seeking leave of the Court. The petitioner shall attend the
      Court on all dates of hearing. The petitioner shall not influence or
      seek to threaten the prosecution witnesses in any manner.
      

    <br /><br>
    <b>Kerala High Court Crime No. 791/2015 of Vattappara ... vs by Advs. Sri.
      T.A. Unnikrishnan
      </b> - 
     
      THE HONOURABLE MR. JUSTICE A.HARIPRASAD Petitioner is the accused
      (husband of the defacto complainant ) in Crime No.791 of 2015 of
      Vattappara Police Station registered for offences punishable under
      Sections 323, 354C(2) and 506(i)I.P.C and Section 66 of the Information
      Technology (Amendment) Act, 2008. Prosecution case, in short, is that
      the accused was having an illicit connection with one of his relatives.
      The defacto complainant, his wife resisted that relationship. Accused
      has committed the offence of voyeurism. He used to take obscene
      photographs of the private parts of his wife on mobile phone. Learned
      counsel for the petitioner subitmitted that their relationship is
      estranged and the complaint is completely false. The relationship is at
      present intact. Considering the nature of allegations, I find that bail
      can be granted to the petitioner with following directions: 1.
      Petitioner shall surrender before the investigating officer within a
      period of two weeks from today and submit himself for interrogation. In
      that event, he shall be released on bail on executing a bond for
      Rs.25,000/- (Rupees twenty five thousand only) with two solvent sureties
      each for the like sum to the satisfaction of the Investigating Officer.
      2. The sureties shall produce documents to establish their identity and
      solvency. The Investigating Officer need not insist on the solvency
      certificate, but other documents can also be perused to find out whether
      the sureties are solvent or not. 3 Petitioner shall appear before the
      Investigating Officer on all Saturdays between 10.00 and 11.00 a.m until
      final report is filed 4. Petitioner shall not influence or intimidate
      witnesses. 5. If any of the above conditions is violated by the
      petitioner, the learned Magistrate having jurisdiction is free to cancel
      the bail.
      
    <br /> </br>
    <b>Madhya Pradesh High Court <br />
      Ramakhtyar Sharma vs The State Of Madhya Pradesh on 24 October, 2016</b > -

     
      The applicant is in custody since 13.09.2016. Though charge sheet is not
      filed in the matter but considering the allegation made against the
      applicant, who is father-in-law of the prosecutrix, which is voyeurism
      and not of rape and sexual cruelty which is alleged against the
      brother-in-law and husband of the prosecutrix, this court feels that
      further custodial interrogation may not be necessary in the case of the
      present applicant. There are no criminal antecedents against the
      applicant. Considering the above said facts and that the early
      conclusion of the trial is a bleak possibility and prolonged pre- trial
      detention is anathema to the concept of liberty and the material placed
      on record does not disclose possibility of the applicant fleeing from
      justice, this Court though is inclined to extend the benefit of bail to
      the applicant but with certain stringent condition. Accordingly, without
      expressing any opinion on merits of the case, this application is
      allowed and it is directed that the applicant be released on bail on
      furnishing a personal bond in the sum of Rs.1,00,000/- (Rs. One Lac
      only) with two solvent sureties, each of Rs.50,000/-, to the
      satisfaction of the concerned trial Court. This order will remain
      operative subject to compliance of the following conditions by the
      applicant :- 1. The applicant will comply with all the terms and
      conditions of the bond executed by him; MCRC.11458/2016 2. The applicant
      will cooperate in the investigation/trial, as the case may be; 3. The
      applicant will not indulge himself in extending inducement, threat or
      promise to any person acquainted with the facts of the case so as to
      dissuade him/her from disclosing such facts to the Court or to the
      Police Officer, as the case may be; 4. The applicant shall not commit an
      offence similar to the offence of which he is accused; 5. The applicant
      will not seek unnecessary adjournments during the trial; and 6. The
      applicant will not leave India without previous permission of the trial
      Court/Investigating Officer, as the case may be. 7. The applicant shall
      mark his attendance once a week before the police station concerned the
      investigation is completed. A copy of this order be sent to the Court
      concerned for compliance. CC as per rules. (Sheel Nagu) Judge sarathe
      Where allegations are just made.
      
    <br /> <br/>
    <b>Madhya Pradesh High Court <br />
      Smt. Sudha Patel vs The State Of Madhya Pradesh on 5 May, 2017</b> -
     
      The case of the prosecution before the trial Court may be summarized as
      hereunder: Deceased Murlidhar was more than 70 years old. He lived in
      village Ankharkheda with his wife Janak Rani. About a year before the
      date of the incident, accused Gopal Patel had prepared 'Sapanna' (a
      place where ladies bathe) in the village. Deceased Murlidhar used to
      pass by that Sapanna in regular course of his daily routine. Petitioner
      Sudha Patel used to bath in that Sapanna after taking off her clothes.
      She used to abuse those who passed by and used to level false
      allegations against them. About 3 or 4 days before the date of the
      incident, she had abused the deceased and had leveled allegation against
      him that he has voyeuristic tendencies; whereon, the deceased had
      reported the matter to Superintendent of Police. When the petitioners
      learnt about the same, they came to the house of deceased and threatened
      that they would beat up the deceased. Petitioner Gopal had created a
      scene and was shouting that if the deceased again passed by aforesaid
      road, they would kill him. Due to aforesaid persistent allegations, the
      deceased committed suicide by consuming poisonous sulphas tablets on
      18.10.2015. In aforesaid circumstances, the possibility that the
      deceased had committed suicide due to persistent allegations and quarrel
      cannot be ruled out. However, it may be noted that the deceased was not
      in any manner related to the petitioners. The petitioners had no control
      over the life of the deceased. If the deceased had felt that he was
      wronged, he could have filed a civil or criminal action for defamation.
      In fact, he had already reported the matter to Superintendent of Police;
      however, instead of waiting for the result of his action, he impetuously
      committed suicide. The petitioners had no interest in seeing the
      deceased dead. In this background, it cannot be said that they had
      created such circumstances wherein the deceased had no option but to
      commit suicide. In fact, they had no reason to suspect that their act
      and conduct would lead to such disastrous consequences. It is clear that
      there was no instigation or incitement on the part of the petitioners to
      abet the commission of suicide. Therefore, in the opinion of this Court,
      the acts and conduct of the petitioners would not fall under the ambit
      of abetment of suicide. 16. Thus, there is no sufficient ground to
      proceed against petitioners Sudha Patel and Gopal Patel under section
      306 or 306 read with section 34 of the Indian Penal Code and charge
      framed against them is not sustainable in the eyes of law. As such, the
      petitioners are discharged in respect of aforesaid offence. 17. In the
      result, this criminal revision succeeds. Petitioners Sudha Patel and
      Gopal are discharged in respect of offence under section 306 or 306 read
      with section 34 of the Indian Penal Code.
      
    <br /><br>
    <b
      >Delhi District Court <br />
      State vs Unknown on 3 June, 2017
      </b> - 
     
      Accused Jagjeet Singh has been charge sheeted by police station Nihal
      Vihar, Delhi for the offences u/s 354B/354C/354D/323/376/450/506/511 of
      the Indian Penal Code (hereinafter referred to as the IPC) on the
      allegations that since two years prior to 15/11/2014 accused had made
      obscene video of the prosecutrix, while she was bathing and forcibly
      made physical relations with her on the pretext of making the said
      obscene video public and thereafter accused outraged the modesty of the
      prosecutrix by tearing her wearing clothes and also gave beatings to
      her. It is also the case of prosecution that on 20/10/2014, accused had
      committed rape upon the prosecutrix. It is also alleged against the
      accused that on 21/10/14 accused had committed house trespass in order
      to commit the offence of rape with the prosecutrix and thereafter
      attempted to rape her and also threatened to pour acid on her face and
      to kill her, if she raised any alarm. In the view of my above
      discussion, it is clear that prosecution has not been able to point out
      beyond reasonable doubt that accused was the person who had committed
      the offence alleged against him, benefit of which is to be given to the
      accused. Hence accused Jagjeet Singh @ Jaga @ Lucky is acquitted of the
      offence u/s 354B/354C/354D/323/376/450/506/511 IPC. However, as per
      provisions of section 437- A Cr.P.C, bail bound of accused is extended
      for further six months on the previous terms and conditions. This is a
      case where the accused was acquitted on the basis that the case should
      be proved beyond reasonable doubt.
      
    <br /> <br/>
    <b>
      Delhi District Court <br />
      State vs. Jaspal Singh @ Sonu Page No. 1/28 on 7 January, 2017
      </b> - 
     
      The accused Jaspal Singh has been sent to stand trial for the offences
      punishable u/s 376/506/354A and 354C IPC on the allegations that on
      15.03.2014 at about 8.00 p.m, at H.No.527, Village Poot Kalan Delhi, he
      committed sexual intercourse with the prosecutrix (name with held to
      protect her identity) against her will and without her consent. He also
      criminally intimidated the prosecutrix by threatening her to show her
      images and that of her sister in public in case she would not adhere to
      his demands of sexual favours. For the aforesaid reasons, I am of the
      view that the prosecution has failed to prove its case against the
      accused beyond reasonable doubt. I therefore, acquit the accused Jaspal
      Singh @ Sonu of the offences punishable under section 376/506/354A/354C
      IPC giving him benefit of doubt. His bail bound is cancelled. His surety
      be discharged. He is, however, directed to furnish bail bound in the sum
      of Rs 50,000/ with one surety in the like amount, in compliance of
      section 437-A Cr.P.C. the mobile phone be returned to its lawful owner
      after the expiry of period of appeal. Now days this has become common,
      capturing nude photos of the victim and then demanding sexual favors, or
      to continue a relationship, or to punish them for ending the
      relationship. This is many times done on the targeted persons.
      Similarly, as even disseminating of such materials is punishable so it
      even recognizes the crime. Fatima rizwana v state rep by ACP, Chennai
      and others AIR 2005 712 JNU mms scandal`,
    },
    {
      title: "Paparazzi and Voyeurism",
      content: `<b>Paparazzi:   </b> 
     
      Paparazzi are not a new concept. Paparazzi are independent photographers
      who take pictures of high-profile celebrities, when they are in their
      ordinary vulnerable moments . These paparazzi make a living by selling
      these photographs to media outlets focusing on tabloid journalism and
      sensationalism. Their actions create nuisance to Public figures and
      celebrities. A person who has been photographed by the paparazzi is said
      to have been "papped". Laws on paparazzi are scarce. Only very few
      countries make it illegal.
      
    <br /> <br/>
    <b>Origin:  </b> 
     
      The film La Dolce Vita by Federico Fellini paved way for new word,
      paparazzi. Walter Santesso’s character of a news photographer named
      paparazzo is the eponym of the word paparazzi. The character of
      Paparazzo, the news photographer was inspired by photojournalist Tazio
      Secchiaroli, was an Italian photographer known as one of the original
      paparazzi. He founded the agency Roma Press Photo in 1955 . In English
      the same Italian word paparazzi is used as a generic term for so called
      freelance photographer. In paparazzi world, often there is competition
      within them. The paparazzi came into limelight during the period of
      Princess Diana.
      
  <br/><br/>
    <b>Rise of paparazzi:  </b> 
     
      The paparazzi world has become an industry of competition to pap
      celebrities and other public figures in their domestic life. The most
      important reason for the rise of paparazzi world is that the normal
      people’s eagerness to know the lifestyle of different celebrities and
      the enthusiasm shown for the celebrity gossips. This enthusiasm and
      eagerness keeps the paparazzi world active. The paparazzi world is
      growing day by day. Kevin smith founder of splash news sells his photos
      to celebrity websites, such as tmz.com, eonline.com and perezhilton.com.
      Splash News assembles about 200 photo sets of small episodes from the
      lives of the stars. The company sells about 10,000 photos a day, making
      it the world's largest dealer of voyeuristic content. Later corbis
      acquired splash (Gary Shenk CEO of corbis) . Francois Regis Navarre
      founded the X17 photo agency in 1996 after he served as a war reporter
      in Iraq and Cambodia for the French newspaper Le Monde. Since then he
      has outshined in the paparazzi world. His company reportedly earns
      roughly $10 million in annual revenues. He published photos of Bradley
      Cooper, one of the lead actors in the recent hit comedy "The Hangover,"
      with his new girlfriend on the balcony of his house. California law
      holds that a person cannot be photographed if a "reasonable expectation
      of privacy" exists. But this has been certainly misused by him. He has
      popularity for his recklessness with law.
      
    <br /> <br/>
    <b> In India:  </b>  
     
      Nowadays in India too paparazzi culture have become successful ranging
      from Tollywood, Kollywood, Mollywood, Bollywood etc. The most popular
      name among paparazzi is Viral Bhayani. Few stars speaking about
      paparazzi said it have become their daily routine to be papped whether
      it is a gym, restaurants or anything. On the other hand many celebrities
      came up against the paparazzi saying Indian paparazzi have gone too far.
      The main point where celebrities are getting papped is at airport, gym,
      hotels etc. some of these photos are made into popular magazines.
      Similarity in voyeurism and paparazzi: Voyeurism and paparazzi act for a
      different cause and for a different reason but what brings them together
      is that they both invade the privacy of others. Though paparazzi are not
      always danger there are some incidences where it was threat for lives of
      celebrities for e.g., Princess Diana, Lindsay Lohan.
      
    <br /> <br/>
    <b>How paparazzi is different from voyeurism:  </b>  
     
      Voyeurism is the act done for sexual gratification such as viewing
      other’s naked part or watching any sexual act secretly. Paparazzi tend
      to make a living by selling their photographs (of high profile people)
      to media outlets focusing on tabloid journalism and sensationalism.
      There is no any law for paparazzi in India. Voyeurism is an offence
      according to IPC under section 354c.
      
    <br /> <br/>
    <b>Scandalous moments of paparazzi:  </b> 
     
      There are many such incidences where these paparazzi have created the
      lives of many celebrities in hustle. One of such famous example is Diana
      the princess of Wales. The speeding car carrying Diana away from the
      paparazzi crashed into a pillar, killing her and her then fiancée Dodi
      al-Fayed. In 2007, Britney Spears had a very public breakdown that
      caused her to shave her head and be confined to rehab. During that
      period she attacked a paparazzi photographer’s car with an umbrella. In
      2012, while the royal couple was vacationing in the south of France,
      paparazzi took photos of a naked Kate Middleton sunbathing on her
      private balcony. Two French media outlets, including the magazine
      closer, published the photos. The royal pair is seeking $1.5 million
      Euros in damages from Closer and fifty thousand Euros from local
      newspaper La Provence, targeting photographers and editors. Paparazzi
      took photographs of George clooney and his wife Amal, holding their
      one-and-a-half-month-old twin babies in their home. The unauthorized
      photographs were published on the front cover of the French magazine
      Voici. George Clooney said in a statement to news outlets “Over the last
      week photographers from Voici magazine scaled our fence, climbed our
      tree and illegally took pictures of our infants inside our home, “and he
      also said that “Make no mistake — the photographers, the agency and the
      magazine will be prosecuted to the full extent of the law. The safety of
      our children demands it.” In 2005 Lindsay Lohan crashed her car into a
      van after being chased by paparazzi in West Hollywood, causing injury to
      Lohan, her passenger and the driver of the van.
      
    <br /> <br/>
    <b>Legality:  </b> 
     
      Due to the nuisance and harassment of paparazzi, some countries have
      restricted their actions. Among them is America, there they are
      protected by the 1st Amendment, especially to protect the children In
      India there is no such laws for paparazzi. Similarly like the US even
      California has laid down some laws to prevent capturing images of
      children in a harassing manner. In 2013, Jennifer Garnerand Halle Berry
      both testified before the California State Assembly Committee on Public
      Safety in support of a bill protecting children from paparazzi cameras.
      The bill was later signed into law, making California the first state to
      establish restrictions against paparazzi when it comes to the families
      of celebrities. The paparazzi track the public figures and celebrities.
      The people getting papped are all celebrities whereas common people show
      eagerness and enthusiasm to know about their day to day activities. In
      such cases, due to the collective eagerness the activity of paparazzi
      also gets promoted. Conclusion: There are many countries where there are
      no any laws to protect them against paparazzi. When the Medias play a
      very important role in this century everyone should be aware of their
      rights and also there should strict action for invading privacy of
      others, though it’s not as serious as voyeurism invading privacy of a
      person or right to stand alone cannot be violated by any person.
      
  `,
    },
    {
      title: "Victims of voyeurism (all from Wikipedia)",
      content: `  
    Voyeurism is an intolerable invasion of privacy. This hurts other people’s sentiments and privacy. One cannot say how tremendously it affects the victim mentally. As voyeurism is a violation of natural privacy its effects are also more than one can expect. Voyeurism has ruined many victim’s life while while some were saved fortunately. 
      <br><br>
    <b>Amanda Toddv -  </b>  

    Amanda Todd was a Canadian girl who fell victim to an online pedophile. She was approached online by a man who seemed as a friendly, and they began communicating. Later he convinced her to take videos of her topless. She made the videos, and sent them to him, and he in turn spread them all over the Internet. She switched schools repeatedly to run away from the people who got to know this. But there was no end to these videos.  Once a video is viral no one can take it back. In April 2014, a month before her 16th birthday, Amanda committed suicide. Such are the situations of people falling prey to such crimes.                                                
    <br><br>
    <b>Smriti Irani -  </b>                                                                 
    Smriti Irani is the current Textiles Minister in the Government of India. On April 3rd Irani raised an alarm about a CCTV camera recording her in the trial room.  The four men were arrested by Calangute police on the evening of April 3, the accused were found to be the employees of the Fabindia; Paresh Bhagat, Prashant Naik Karim Lakhani, Raju Payannche, Ms Chaitrali Sawant. Her case is still pending in the court for the final hearing. There are rare chances of like these to escape from voyeurism. It is impossible for all to identify these spy cameras as it is so minute and rarely people notices it.  
    <br><br>
    <b>  Aruna Shanbaug -  </b> 
    The well known case of Aruna Shanbaug was initially a voyeurism case which later led to rape and to prolonged life unit she died from pneumonia. The offender who raped her used to watch her occasionally. Later on that unfortunate day while she was changing her clothes he, triggered by her body finally committed the rape which made her to suffer for 42 years in hospital. 
    <br><br>
    <b>  Amanda and Stephanie -  </b> 
     In 1999, Debra Gwartney's 14-year-old daughter saw a man taking photos outside her window. Police found evidence that someone had climbed on a bucket to peer into that window numerous times. This was one of the famous case of that time. 
     <br><br>
                                            
     <b>Young men who were filmed‼!   </b> 
    Though usually a large no. of female victims is victimized under voyeurism many of men are even offended by voyeurism. Martin Thrower, Rector of St Mary’s, Hadleigh, and Dean of Bocking charged with filming young men in public lavatories. Like this, there are many other men who are victimized so it cannot be denied that only women are victimized. Many people are victimized under voyeurism without knowing they are being voyeured. 
    <br><br>
  <b>Tiziana Cantone -  </b> 
             Tiziana Cantone was an Italian woman who committed suicide after private videos of her having sex that she sent to a former boyfriend and other acquaintances via WhatsApp were uploaded to public internet sites, where one went viral due to her reacting when filmed performing fellatio in front of a car with the words: "You're filming? Good!” (Italian: Stai facendo il video? Bravo!)
    After winning a -legal case over the right to be forgotten, and then being ordered to pay 20,000 Euros in legal costs, she committed suicide in September 2016. Her mother Maria Teresa Giglio still fights to remove the video from the Internet.
    
  
`,
    },
    {
      title: "VOYEURS",
      content: ` 
Voyeurism before was a discreet offence but Nowadays voyeurism is a far spreading offence. Voyeurism is unpredictable. It can happen at anywhere, anytime and to anyone. Video voyeurism spread all over the world within no time and that creates a pressure in the society where victims are put under a mental stress. This also creates the fear of invading privacy. 
Voyeur is a person who gains sexual pleasure from watching others when they are naked or engaged in sexual activity. Most of the voyeurs are the sufferer of paraphilic disorder. Paraphilia is an intense sexual arousal to atypical objects, situations, fantasies, behaviors, or individuals. Such attraction may be labeled sexual fetishism. Paraphilic disorders are emotional disorders. Voyeuristic disorder is one of several psychiatric sexual disorders known as paraphilic disorders. Apart from voyeurism, Exhibitionism, Sexual masochism, Frotteurisim, Sexual sadism, Pedophilia, Transvestism, Fetishism are types of paraphilic disorders. The exact reason for these disorders are not known but it can be due to any depression of sexual violence in childhood, or any matter that created their life miserable. Trust and confidence is the preference of the habitual voyeur. Some of the famous voyeurs are listed below.
   <br><br>
<b>Peeping Rabbi -   </b> 

Rabbi Barry Freundel, 66, a Modern Orthodox rabbi in Washington, D.C. who secretly filmed women in his synagogue’s mikvah. Freundel is believed to have violated the privacy of at least 150 women, whom he filmed while they undressed and showered at the mikvah, or ritual bath, including members of his synagogue, candidates for conversion to Judaism and students at Towson University in Maryland, where Freundel taught classes on religion and ethics.                                                                                                                              Freundel began serving his sentence in May 2015, was sentenced to jail after pleading guilty to 52 counts of voyeurism. After his acts came to light, he is referred as peeping rabbi. 
<br><br>
<b>Luis Mijangos -   </b> 

Luis Mijangos, a California computer hacker and wheelchair-bound paraplegic, invaded the privacy of more than 100 women; he was also a perverse sextortionist on a sordid personal crime wave. Mijangos hacked into women’s computes, stole sexually explicit photos, and then attempted to blackmail the women into making him personal porn videos by threatening to post their photos on the Internet. Mijangos was eventually caught and sentenced to a six-year jail term. 
                  
<br><br>
<b>Michael David Barrett -   </b>  
                                                  

In 2009, Michael David Barrett pleaded guilty to creating peepholes in hotel rooms and secretly shooting nude videos of sportscaster and TV personality Erin Andrews. Michael Barrett then posted the videos on Internet sites, including YouTube. The nude clip topped Google’s list of most searched items. Barrett went to prison for 2 ½ years. In March 2016, a jury awarded Erin Andrews $55 million in a civil lawsuit over the secret recording. Her original suit asked for $75 million.

                        
<br><br>
<b>Gerald Foos -   </b>  
                                                     
The New Yorker features an article by Gay Talese titled “The Voyeur’s Hotel.” It tells the story of an hotelier named Gerald Foos who bought a hotel near Denver, Colorado in order to watch his guests have sex. Gerald Foos didn’t just watch his guests have sex he recorded and documented their sexual behavior. Foos regarded his voyeurism as serious research, and he viewed himself as a sex researcher similar to Alfred Kinsey. Foos was never caught, and only now, 18 years after he sold his Denver motel and 33 years since he began communicating with reporter Talese. He has decided to go public with his sex manuscript.
                                              
<br><br>
<b>Benjamin Wilkins -   </b> 
Benjamin Wilkins, a BBC London producer, hid a CCTV device in a smoke alarm and recorded his encounters with at least 10 women. Another secret camera was hidden in a “moveable ornament” in his South London bathroom. In 2008, Wilkins was jailed for eight months after admitting to 11 counts of voyeurism. The BBC peeper was caught when his girlfriend discovered a stash of DVDs labeled with the names of ex-girlfriends and BBC colleagues. Police recovered over 50 hours of footage. 
                    
<br><br>
<b>Anuj Parekh -   </b> 

Blacksburg Police arrested 20-year-old Virginia Tech student Anuj Shailesh Parekh Wednesday, March 30 as a result of an ongoing voyeurism investigation. 

<br><br>
<b>Swami Rampal Baba -   </b>  
       Swami Rampal baba, self proclaimed god man was arrested by Haryana police. He was arrested for not responding to court summons, for trial in the murder case. He was arrested after two day long operation in Hissar. During the search operations police found condoms, drugs, adult content, and even a secret camera in the ladies’ toilet at Rampal’s Satlok Ashram. He was not charged for these voyeurism activities. Later in 2017 he was acquitted in two other cases.                                             
                     
       
       <br><br>
       <b>Kenneth Elkins -   </b>  
Kenneth Elkins, 55, former director of the Naval Surface Warfare Center at the McAlester Army Ammunition Plant, secretly videotaped Simmons and seven other employees who used the office restroom from July to August 2013. Police also found hidden cameras in the bathroom of Elkins’ 16-year-old daughter.

<br><br>
<b>Sean Smith -   </b> 

A transgender woman in Idaho was arrested on Tuesday and accused of taking pictures of a woman trying on clothes in a Target changing room. The suspect is Sean Smith, 43, who also goes by the name Shauna Patrick Smith. The suspect faces a charge of felony voyeurism, punishable by up to five years in prison.
                             

<br><br>
<b>Dr. Vincent Nadon -   </b> 

Dr. Vincent Nadon works in the Rideau clinic street which runs by the University of Ottawa Health Services. In his examining room, a woman while taking her clothes off discovered that a cell phone was recording her and she felt horrified. She reached out to senior administrators of University of Ottawa. And with help of her some friends she lodged a complaint against him. Ottawa police laid 43 additional charges of sexual assault case and 40 counts voyeurism.
<br>
Thus, it’s a serious disorder where it can disturb the balance of society. Nowadays the participation of voyeurism does not last within the voyeur and the victim but it extends to the whole world who watches it without knowing, that was an illicit material.
Now the time have come to seriously consider this issue and take preventive measures instead of only punishing the accused and giving compensatory aid to the victim.  

`,
    },
    {
      title: "What you should do if you become victim of voyeurism.",
      content: `<b>Introduction:   </b> <br>
    In 2013, the Indian parliament made amendments to the Indian Penal Code and introduced Voyeurism as a criminal offence.[ ] Voyeurism is the spying of people engaged in their personal or intimate activities, such as undressing, engaged in sexual activity or using a lavatory, without their knowledge. Such acts are often recorded and made viral on the interest and social media sites. The shame and disgrace which the victim has to suffer at the hands of society prevents the victim from raising a voice against Voyeurism. Any person who goes through Voyeurism must register their case without any fear. Another alarming fact is that the people in society are not aware of the provisions available in case of occurrence of Voyeurism. Let us learn about what should be done in case you become a victim of Voyeurism.
    <br><br>
    <b>Be Alert:  </b> <br>
    Voyeurism is an offence where the privacy of people is invaded without their consent. No one can predict when this may happen or with whom it may happen. Sometimes any person who accidently observes another person engaged in an intimate or private activity continues to keep an eye on them or capture their private activities; this also amounts to Voyeurism. Reducing opportunities for voyeurism may reduce the incidence so it is advised to check thoroughly the dressing rooms and lavatories before using them. Check whether the doors and windows of the room are properly closed. Check items in which there is a possibility of hiding a hidden camera. Check everything in the room which may create even the slightest doubt in your mind. If possible turn off the lights and check for any red light beam which may be the sign of a hidden camera. You may also slowly shine the flashlight around every inch of the room. Camera lenses are made with glass, so you'll see a reflection of the light on the lens if there's a camera anywhere. Also, wireless camera detectors or jammers maybe purchased to check for Voyeurism. Moreover, make yourself aware of the ways in which voyeurism is committed or cameras are hidden.
    As said earlier, voyeurism can take place anywhere at any time. But there are some places where often voyeurism is reported and these places have a good chance to be papped by voyeurs. 
    <br>
    1.	Restroom in colleges.<br>
    2.	Public toilets such as in : 
    i]	Malls, 
    ii]	Theatres,
    iii]	Bus stand, 
    iv]	Shopping complex
    v]	Restaurants
    vi]	Hospitals <br>
    3.	Other public places like gym, studios, seashore, parks etc.
    
    <br>
    Only allow certified mechanics in your house to manage any repairs in the house. 
    Of course, it is not always possible to detect such cameras given the present say technologies which can disguise a camera in a screw as well. But, it is always better to be alert. It is to be noted that this is just a precaution measure. In no way can a victim be blamed for not being alert.
    <br><br>
    <b>Steps to detect hidden cameras:  </b> 
    <br>
    1)	Check for cameras near valuable items.<br>
    2)	Consider mirrors in unexpected locations.<br>
    3)	Check areas with the best fields of vision (e.g. Ceilings, rooftops).<br>
    4)	Look for tinted glass or plastic domes.                    <br>  
    5)	Check plants and trees.<br>
    6)	Purchase a wireless camera detector.                       <br>
    7)	Place a cell phone and listen for interference.                <br>                                          
    <br>
    <b>Who are victims of voyeurism?   </b> 
    <br>
    The victims usually do not know they are being watched, photographed, and recorded in these crimes where they expect their privacy. Many a times people using public toilets, dressing rooms and honeymoon suites are victimized. Any person can become a victim of Voyeurism, irrespective of their class, age, gender. Etc. Famous individuals like Smriti Irani, Hardik Patel have also become victims of voyeurism. 
    <br><br>
    <b>Impact of Victimization in voyeurism:  </b> 
    <br>
    In section 2 of the Code of Criminal Procedure, 1973 (wa) “victim” [ ] means a person who has suffered any loss or injury caused by reason of the act or omission for which the accused person has been charged and the expression “victim “includes his or her guardian or legal heir;’ 
    Voyeurism is such a crime where it leads to other crimes questioning their moral values. Once voyeurism has taken place the victims’ private activities are viral on the net and no one can prevent it as it only goes spreading. Moreover the victims are always blackmailed for money, or to have sex which is against their will which also amounts to rape. The impact of voyeurism is long-term and difficult to overcome. Victims are often frustrated, fearful, confused, and angry. It affects all victims differently. In general, it causes psychological trauma to the victims. Voyeurism impacts people on an emotional, physical, financial, psychological, and social level. Some victims feel self-hatred when they know they are victims of some crime. They get into depression and depression is another tough mental health disorder where they either commit suicide or they create violence or revenge. Due to voyeurism not only the individual is affected but also their marital relationships are also likely to be affected. Even Social injuries and secondary victimization affects the victims a lot. Social injuries are those that may be caused by the society in consequences of the crime.  Ill treatment by any family member friend, colleagues, and any person in the context of crime is referred as social injuries. Secondary victimization refers to behaviors and attitudes of social service providers that are victim-blaming and insensitive, and which traumatize victims of violence who are being served by these agencies.
    <br>
                So it is important to remember that victims are always the output of others trap and they do not choose to be victimized so it is the duty of every individual in the society to bring them back to normal life rather than degrading them.    
    <br><br>
    <b> Remedies :   </b> 
    <br>
    Every person should always be aware of their rights. In case of an offence of Voyeurism, the following procedures can be followed:[ ]
    
    1) The first step to be followed in case of an offence of Voyeurism is to lodge a complaint in the nearest police station. If the victim is a woman, the information provided ought to be recorded by a woman police officer or any woman officer. Further in case the victim is temporarily or permanently mentally or physically disabled, then such information is recorded by a police officer at the residence of the person seeking to report such offence or at a convenient place of such person’s choice (157 CrPC).<br>
    
    2) Victims aggrieved by a refusal on the part of an officer in charge of a police station to record the information may send the information in writing and by post, to the superintendent of police.<br>
    
    3) If they face any refusal from the superintendent or if no action is taken, then they can approach the first class Magistrate or second class Magistrate empowered by the Chief Judicial Magistrate.<br>
    
    4) The victim/s can approach NGO’s curbing voyeurism by calling toll-free number. Our NGO also has a toll free helpline number _______________. We are always here to help you.<br>
    
    5) Remember that any person can file a complaint on behalf of the victim as well. Access to justice and fair treatment is provided for in Sec 2(w)(a) of the Code of Criminal Procedure. This new definition includes a guardian or legal heir of the victim as a victim and confers them the right equivalent to victim.<br>
    
    6) To cope up with psychological trauma it is advisable to provide the victims with efficient counseling or rehabilitation assistance.<br>
    
    7) The next best step would be to acquire a good lawyer to fight your case. By Sec 24(8) of the Code of Criminal Procedure, the victim is able to engage his advocate of his choice to assist the public prosecutor. Also, free legal aid is available in ___________ if the victim or family is not financially well off.<br>
    
    8) The law on Voyeurism i.e. S. 354 C provides for a punishment of not less than 1 year and not exceeding 3 years of imprisonment along with a fine. In case of repeat offenders, the punishment is not less than 3 years and up to 7 years of imprisonment along with fine.<br>
    
    9) Restitution<br>
    
    10) Compensation- Sec357A has been inserted in the Code of Criminal Procedure 1973, the Code of Criminal Procedure (Amendment) Act, 2008, which provides for compensation to victims of crime. The victim can make use of this section to gain compensation for the loss caused.<br>
    <br>
    <b>Conclusion:  </b> 
    <br>
    Now, with the emerging use of social networks, its disadvantages are also rising where one can see one’s personal activities are aired in these platforms without their knowledge. This kind of exposure ruins the victim’s dignity and life. It is of utmost importance to be aware of our rights and make good use of the legal system to ensure justice.
    
  `,
    },
  ];
}

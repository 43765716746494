import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MediaService } from "../../services/media.service";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";
import * as $ from "jquery";
import "magnific-popup";
@Component({
  selector: "app-photo-gallery",
  templateUrl: "./photo-gallery.component.html",
  styleUrls: ["./photo-gallery.component.scss"],
})
export class PhotoGalleryComponent implements OnInit {
  constructor(
    private mediaService: MediaService,
    private titleService: Title
  ) {}
  image = "gallary_image/";
  public imageUrl = environment.imageUrl;
  public images;

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Photo Gallery");
    this.getAllActiveImageList();
  }

  @ViewChild("imgGallary") public imgElement: ElementRef;
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.imgElement.nativeElement) {
        $(this.imgElement.nativeElement).magnificPopup({
          delegate: "a",
          gallery: {
            enabled: true,
          },
          type: "image",
        });
      }
    }, 2000);
  }
  getAllActiveImageList() {
    this.mediaService.getAllActiveImageList().subscribe((response) => {
      this.images = response.data;
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  footerGet;
  contact;
  constructor(private route: Router) {}
public currentYear:number;

  ngOnInit() {
    this.contact = this.route.url;
    this.currentYear = new Date().getFullYear();
    if (this.contact == "contact") {
      this.footerGet = false;
    }
  }
}

import {
  NgModule,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormsModule, FormGroup, FormControl, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";

import { MainService } from "../../services/main.service";
class Affiliation {
  constructor(
    public organization: string = "",
    public registrationno: string = "",
    public businessType: string = null,
    public address: string = "",
    public country: string = null,
    public state: string = null,
    public city: string = null,
    public pincode: string = "",
    public mobileNumber: string = "",
    public email: string = "",
    public licence_copy: string = ""
  ) {}
}
@Component({
  selector: "app-affiliation",
  templateUrl: "./affiliation.component.html",
  styleUrls: ["./affiliation.component.scss"],
})
export class AffiliationComponent implements OnInit {
  pdf = "../../assets/lesson2.pdf";
  public imagePath;
  imgURL: any;
  public message: string;
  extention;
  constructor(
    private mainService: MainService,
    private titleService: Title,
    private toastr: ToastrService
  ) {}

  public businessTypes;
  public myRecaptcha = false;
  model: Affiliation = new Affiliation();
  @ViewChild("f") form: any;

  public errorRequired = "is required.";
  file;
  MsgAction = false;
  spinnerLoader = false;
  Msg: any;
  countrys;
  status;
  states;
  citys;
  validCheck;
  citysStatus = false;

  ngOnInit() {
    this.titleService.setTitle("Get Affiliation");
    this.businessTypes;
    this.getAllCountry();
    this.getAllEntity();
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    window.scroll(0, 0);
  }

  getAllCountry() {
    this.mainService.getAllCountry().subscribe((response) => {
      this.countrys = response.data;
    });
  }

  getAllEntity() {
    this.mainService.getAllEntity().subscribe((response) => {
      this.businessTypes = response.data;
    });
  }

  getAllStatesByCountryId(countryId) {
    let data = new HttpParams().set("countryId", countryId);
    this.mainService
      .getAllStatesByCountryId(data)
      .subscribe((response: any) => {
        this.states = response.data;
      });
  }

  getAllCityByStateId(stateId) {
    let data = new HttpParams().set("stateId", stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;
      this.citysStatus = response.status;
    });
  }
  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    let ext = files[0].name.split(".").pop();
    this.extention = "." + ext;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    let data = new HttpParams()
      .set("name", this.model.organization)
      .set("registrationno", this.model.registrationno)
      .set("business_entity", this.model.businessType)
      .set("address", this.model.address)
      .set("country", this.model.country)
      .set("state", this.model.state)
      .set("city", this.model.city)
      .set("zipcode", this.model.pincode)
      .set("contactno", this.model.mobileNumber)
      .set("email", this.model.email)
      .set("website", "www.hotelkamatmourya.com")
      .set("licence_copy", this.imgURL)
      .set("extention", this.extention);

    const urlApi = "affilation/createAffilation";

    this.mainService.postApi(urlApi, data).subscribe((response: any) => {
      this.status = response.status;

      if (response.status == "true") {
        this.toastr.success("Your application sent successfully.");
        this.validCheck = false;
        this.form.reset();
        this.myRecaptcha = true;
        this.imgURL = null;
      } else {
        this.toastr.error("Something went wrong");
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NgbAccordionConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
  providers: [NgbAccordionConfig],
})
export class FaqComponent implements OnInit {
  items = [];
  pageOfItems: Array<any>;
  p: number = 1;
  collection: any[];
  constructor(config: NgbAccordionConfig, private titleService: Title) {
    // customize default values of accordions used by this component tree
    config.closeOthers = true;
    config.type = "info";
  }
  public isCollapsed = false;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("WAVF : Frequently asked quetions");
    this.collection = this.faqs;
    window.scroll(0, 0);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  public faqs = [
    {
      title: "What should I do if I become a victim of Voyeurism?",
      content:
        "If you become a victim of Voyeurism, you should immediately report the same to the nearest Police Station. The victim has the right to lodge a First Information Report. The Police will attempt to search and seize the Voyeuristic material in the hands of the accused. We further advise you to approach the WAVF through our Volunteers and avail free guidance which will help you deal with the practical aspects of the case. If the Police refuse to take cognizance or you are hesitant to approach the Police or the Police do not deal with your case fairly, the WAVF is here to help you seek justice. ",
    },
    {
      title:
        "Whom do I approach if someone is blackmailing me with my Voyeuristic material? ",
      content:
        "In cases of videos of extra marital relations or sexual activities considered socially immoral or other private activities, the victim may be blackmailed or threatened. In such cases, we advise you to approach WAVF and we will keep all your information confidential. At the same time, we will legally and technically guide you free of cost on what steps are to be taken in order to ensure that the Voyeuristic material is not leaked as well as all appropriate legal action is taken. Additionally, we also provide free psychological counseling.",
    },
    {
      title:
        "Is it safe to show or hand over my voyeuristic material to the Police at the time of filing a complaint?",
      content:
        "Not every time. This is a very complicated situation for a victim to deal with because the victim may face humiliation at the hands of the Police if the police officer handling their case does not honestly comply with his duties or is negligent in dealing with the Voyeuristic material. The Police do not have the right to acquire any evidence such as the Voyeuristic material in their personal capacity and hence the victim should refrain from forwarding the material to the Police officer’s personal mobile number, etc. It is the duty of investigating officer to recover such material in possession of the accused, if any. Therefore such material should not be handed over by the victim to the Police as evidence of a crime to initiate a legal action unless and until legal procedure like an FIR is lodged and acknowledgement of the received material is given to the victim. The Police must give to a victim, in writing, that they have received the electronic files of Voyeuristic material and then it should be sealed and sent to a Government Forensic Laboratory. The Victim must take all such precautions to ensure that such video of the voyeuristic material is not made viral by the Police or any person dealing with the case. Although it is necessary that such material be kept in proper form and custody and only limited number of officials should be allowed to access the said material, there is no specific legal procedure for the same at present.",
    },
    {
      title: "How can I find out if I am victim of Video Voyeurism?",
      content:
        "At present, there is no professional mechanism to find out if you are a victim of Voyeurism. However, WAVF is involved in research in order to develop a mechanism and other artificial intelligence which will help the general public search if any Voyeuristic video of theirs is posted online. WAVF also makes recommendations to the Government to work in this direction.",
    },
    {
      title:
        "Can pornography websites use my Voyeuristic material for commercial gain without my consent?",
      content:
        "Yes, currently voyeuristic material and non-consensual videos of sexual nature are used by pornography (porn) websites for commercial gain. There is a huge demand on the porn market for such material. Recently, a popular porn website Pornhub removed 10 million videos uploaded from unverified accounts after Visa and Mastercard halted payments on the site due to allegations of child sexual abuse and rape. This is a step in the right direction but numerous porn websites continue to allow such content to be uploaded.",
    },
    {
      title:
        "How can I take action against pornography websites for uploading my private video or Voyeuristic material without my consent?",
      content:
        "As of today, you have to file a complaint with the nearest Police Station. The Police Cyber Crime Branch deals with such matters. It is very difficult to remove a Voyeuristic video from a porn site even with Governmental intervention as the server of the website may be present in another country. Negotiating with another country through the Ministry of External Affairs and Ministry of Home affairs does not always yield the desired removal of material from the porn site. Moreover, this process is very tedious and can take days. Interacting directly with the website is another option but this is also not always successful. Even banning such website by the Government is not full proof as this ban can be easily bypassed. Additionally, once the video is uploaded it may be viewed, downloaded and shared by millions of viewers and hence removing the video from all such devices is impossible.",
    },
    {
      title:
        "Is it legal to secretly record any sexual acts of my extra marital relationship? Do I have the legal right to file a complaint if such video is recorded or if it is disseminated?",
      content:
        "You have a right to file a complaint if the offence of Voyeurism is committed against you even if the video is one of your extra-marital relationship. However, whether or not such a recording amounts to Voyeurism depends on the intention with which such recording is done. If a person records you secretly for the purpose of amusement, entertainment, sexual arousal, gratification, profit, degrading or abusing you then it amounts to Voyeurism. If such material is used against you only as evidence in a court of law to prove your extra marital relationship by your husband or relatives of husband or any legally interested person then it will not amount to Voyeurism. However if such a video is disseminated without your consent, irrespective of the intention, it still amounts to Voyeurism.",
    },
    {
      title: "What should I do to overcome depression caused due to voyeurism?",
      content:
        "You should immediately approach a psychologist for treatment of the depression. WAVF provides psychological & medical aid to a victim of voyeurism free of cost. However, if you prefer you may approach a genuine psychologist of your own choice. Always remember that - this too will pass. For more help, call our helpline +91 9021310000.",
    },
    {
      title:
        ".	How can I trace a hidden camera in a public dressing room or bathroom or in a hotel or other such place?",
      content:
        "No foolproof method is available to detect a hidden camera. However, you can take certain steps to reduce the possibility of you being recorded secretly. Check whether the doors and windows of the room are properly closed. Check items in which there is a possibility of hiding a camera. If possible turn off the lights and check for any red light beam which may be the sign of a hidden camera. You may also slowly shine the flashlight around every inch of the room. Camera lenses are made with glass, so you will see a reflection of the light on the lens if theres a camera anywhere. A good video recording requires good lighting and so you can reduce the lighting in the room to ensure that even if a video is recorded, it does not clearly capture you. You may cover any mirrors using newspaper and sticky tape or cloth or blur the mirror by rubbing a wet soap bar over it. Additionally, you can change the position of the items kept in the area, wherever possible. Moreover, make yourself aware of the ways in which voyeurism is committed or cameras are hidden. However, given the technological developments, all these methods can easily fail. WAVF provides for a list of Voyeurism-free premises on our website where you can use the search engine to check for premises which have been approved by WAVF as Voyeurism-free.",
    },
    {
      title:
        "Are spy camera detectors, jammers or mobile apps for detecting hidden cameras really useful?",
      content:
        "Devices such as spy camera detectors, jammers or mobile app for detecting hidden cameras are not foolproof. The usefulness of these devices or apps depends on the quality and type of the spy camera being used. Mobile apps are only able to detect certain rays of light which many spy cameras are able to evade. It is best not to rely on such Anti Voyeurism gadgets.",
    },
    {
      title: "I am addicted to Voyeuristic Porn. How can I come out of it?",
      content:
        "Voyeuristic pornography is not only legally and morally wrong but one can also get addicted to it. An addiction is developed when the behavior starts to disrupt your daily functioning. The most important thing you can do to come out of such an addiction is to seek help with a psychologist. You can come out of this addiction with strong determination and proper psychological counseling. You can also consult the team of psychological experts from WAVF who can help you address such an addiction.You can also start the process by deleting all Voyeuristic porn from your devices. You may ask another person to install an anti-pornography app on your phone to prevent you from accessing all kinds of porn so the password will not be known to the addict. In case of addiction to Voyeuristic porn only, you can access pornography websites which are devoid of such porn and block other websites. You can also join a sport or hobby that you enjoy. Other treatments such as therapy, medication and support groups also work very well. Contact us at support@wavf.org.",
    },
    {
      title:
        "What are the laws with respect to conducting sting operations to expose crime? Can I conduct a sting operation to expose an illegal activity? ",
      content:
        "There are no laws which expressly mention and regulate sting operations in India but certain other countries do have such laws. However, general legal provisions in the Indian law can be invoked to protect the rights of those who conduct sting operations in good faith. The Delhi High Court in Aniruddha Bahal v. State  held the right to sting as an integral part of the freedom of speech and expression under Article 19 when done for larger public interest. On the other hand, some Courts have imposed restrictions on such a right. Wiretapping which is a kind of sting operation is regulated under the Telegraph Act of 1885. In People’s Union for Civil Liberties v. Union of India , the Supreme Court has drawn certain guidelines with respect to wiretapping. In other countries certain laws regulate Private Detectives and sting operations conducted by them. Right to privacy is not an absolute fundamental right and reasonable restrictions may be imposed by the State. The validity of Sting operations depend on the intention with which they are conducted. Hence, although there is no concrete law on sting operations in India, you can conduct sting operations in good faith. The Tehelka Operation West End and Watergate scandal are some famous sting operations exposing corruption in the American and Indian Governments. ",
    },
    {
      title:
        "Is there a trustworthy agency to search and inspect certain premises for spy cameras and other devices before using such premises?",
      content:
        "Yes, there is. WAVF provides services for search and inspection of premises and even provides certification to premises such as hotels, lodges, changing rooms, etc on their application.",
    },
    {
      title: "What is mean by Revenge Porn?",
      content:
        "Nude or sexually explicit videos or images captured by a sex partner during trustworthy relationship with or without consent but made viral after breakup without consent of the person visible in the image or footage in order to have revenge.",
    },
    {
      title: "How can I find Voyeurism-free premises?",
      content:
        "You can use the WAVF search engine on our website www.wavf.org to search for Voyeurism-free premises certified by WAVF. Moreover, the entities that have been issued a Voyeurism-Free certificate by WAVF usually display their certificates on their premises. ",
    },
    {
      title: "Is it safe to use public dressing rooms or bathrooms?",
      content:
        "Mostly, it is not safe. For this reason, WAVF has introduced the certification process whereby premises are granted certificates on adhering to the privacy standards set by WAVF. This certificate will help indicate that the premises are Voyeurism-free.",
    },
    {
      title: "Are nude video chats safe?",
      content:
        "Nude chats can be easily recorded by the opposite viewer and used for revenge porn. Depending on the trustworthiness of the person, the nude chat may be safe or unsafe. In addition to this, there is a possibility that such chats and videos may be hacked by a third party. This can further lead to blackmail of both parties or posting of the video online. ",
    },
    {
      title:
        "Can I legally search for and watch Non-consensual porn or Voyeuristic videos online?",
      content:
        "As of today, certain pornography websites have been banned by the Indian Government and Governments of some other countries and hence those websites cannot be accessed directly. However, there are many means of bypassing these blockages. There is no law or regulation which makes any kind of porn illegal in India. Hence, watching pornography and even Non-consensual, Voyeuristic Pornography is not illegal. Given this, Voyeuristic pornography non-consensually uploaded causes a lot of emotional, physical and psychological trauma to the victims and by watching such videos we are not only increasing its demand but contributing to the victim’s trauma.  ",
    },
    {
      title:
        "Can I legally search and watch child pornography or Voyeuristic videos of children?",
      content:
        "No, Child pornography has been banned in India under the Protection of Children from Sexual Offences Act, 2012. Even browsing, watching or downloading any pornographic videos of children (including Non-consensual and Voyeuristic videos of children) is an offence under this Act and the person committing this act can be liable to punishment of 5 years imprisonment and upto Ten Lakh Rupees fine. ",
    },
  ];
}

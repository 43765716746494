import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { EventService } from "../../services/event/event.service";
import { Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
class Feedback {
  constructor(
    public userName: string = "",
    public email: string = "",
    public phone: string = "",
    public subject: string = "",
    public comment: string = ""
  ) {}
}
@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
export class EventComponent implements OnInit {
  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private toastr: ToastrService
  ) {}

  showNavigationArrows = true;
  showNavigationIndicators = true;
  // FOR MAP
  public lat: number;
  public lng: number;

  currentUser;
  event_id;
  error;
  url;
  public userId = "";
  created_at;
  eventCity;
  eventCountry;
  eventState;
  event_city_id;
  event_country_id;
  event_description;
  event_end_date;
  event_image;
  event_location;
  event_start_date;
  event_state_id;
  event_title;
  id;
  status;
  updated_at;
  activeEvent;
  myRecaptcha;
  public interes;
  public userLogin = false;
  public Interested = true;
  public interestTitle;

  @Input() itemCol = 1;
  @Input() true;
  @Input() loop = false;
  @Input() dots = false;
  @Input() nav = true;

  MsgAction;
  model: Feedback = new Feedback();
  @ViewChild("f") form: any;
  validCheck;
  Msg;
  spinnerLoader = false;
  public errorRequired = "is required.";

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Events by WAVF ");
    this.getCurrentUser();
    this.url = window.location.href;
    this.event_id = this.route.snapshot.paramMap.get("id");
    this.getAllUpcomingEventList();
    if (this.userLogin) {
      this.interestTitle = "";
    } else {
      this.interestTitle = "Login to register interest";
    }

    window.scroll(0, 0);
  }
  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser) {
      this.userLogin = true;
      this.Interested = true;
      this.userId = this.currentUser.user_id;
    }
  }

  getAllUpcomingEventList() {
    this.eventService.getAllUpcomingEventList().subscribe((response) => {
      this.activeEvent = response.data[0];
      this.error = response.error;
      this.created_at = this.activeEvent.created_at;
      this.eventCity = this.activeEvent.eventCity;
      this.eventCountry = this.activeEvent.eventCountry;
      this.eventState = this.activeEvent.eventState;
      this.event_city_id = this.activeEvent.event_city_id;
      this.event_country_id = this.activeEvent.event_country_id;
      this.event_description = this.activeEvent.event_description;
      this.event_end_date = this.activeEvent.event_end_date;
      this.event_image = this.activeEvent.event_image;
      this.event_location = this.activeEvent.event_location;
      this.event_start_date = this.activeEvent.event_start_date;
      this.event_state_id = this.activeEvent.event_state_id;
      this.event_title = this.activeEvent.event_title;
      this.id = this.activeEvent.id;
      this.status = this.activeEvent.status;
      this.updated_at = this.activeEvent.updated_at;
      this.lat = this.activeEvent.latitude;
      this.lng = this.activeEvent.longitude;
      this.checkUserInterestedForEvent();
    });
  }
  checkUserInterestedForEvent() {
    this.getCurrentUser();
    const interes = new HttpParams()
      .set("event_id", this.id)
      .set("user_id", this.userId);
    this.eventService
      .checkUserInterestedForEvent(interes)
      .subscribe((response) => {
        const interesId = response.is_user_interested;
        if (interesId == "0") {
          this.Interested = true;
        }
        if (interesId == "1") {
          this.Interested = false;
        }
      });
  }
  markAsInterestedForEvent(): void {
    this.getCurrentUser();
    if (this.currentUser) {
      const interes = new HttpParams()
        .set("event_id", this.id)
        .set("user_id", this.userId);
      this.eventService
        .markAsInterestedForEvent(interes)
        .subscribe((response) => {
          this.interes = response.data[0];
          this.Interested = false;
        });
    } else {
      this.userLogin = false;
    }
  }
  markAsUninterested(): void {
    this.getCurrentUser();
    if (this.currentUser) {
      const interes = new HttpParams()
        .set("event_id", this.id)
        .set("user_id", this.userId);
      this.eventService.markAsUninterested(interes).subscribe((response) => {
        this.interes = response.data[0];
        this.Interested = true;
      });
    } else {
      this.userLogin = false;
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;

    this.getCurrentUser();
    const data = new HttpParams()
      .set("event_id", this.id)
      .set("userName", this.model.userName)
      .set("email", this.model.email)
      .set("phone", this.model.phone)
      .set("subject", this.model.subject)
      .set("comment", this.model.comment);
    this.eventService.eventFeedback(data).subscribe((response) => {
      const error = response.error;
      this.status = response.status;
      if ((response = true)) {
        this.toastr.success("Thank you for your feedback");
        this.validCheck = false;
        this.form.reset();
        this.myRecaptcha = true;
      } else {
        this.toastr.error(error);
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }

  // FOR PAGE SHARE
  public metaUrl = window.location.href;
  public metaTitle = "WAVF events";
  public metaDescription =
    "WAVF is a non-profit organization against the offence of Voyeurism registered in India. We are a group of public spirited persons from various fields aiming to create a Voyeurism-free world where the privacy of every individual is protected.";
  public metaImage = environment.imageUrlShare + "wavf.png";
  public metaTags = "WAVF, law, lawbook, voyeurism";
}

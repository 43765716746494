import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, fromEvent, merge, of } from "rxjs";
import { first, mapTo } from "rxjs/operators";
import { User } from "./_models/user";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUser: User;
  online$: Observable<boolean>;
  network;
  constructor(private router: Router) {
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, "online").pipe(mapTo(true)),
      fromEvent(window, "offline").pipe(mapTo(false))
    );
  }

  donate() {
    this.router.navigate(["/donation"]);
  }
}

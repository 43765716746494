import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-about-hindi",
  templateUrl: "./about-hindi.component.html",
  styleUrls: ["./about-hindi.component.scss"],
})
export class AboutHindiComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}

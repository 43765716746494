import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };

@Injectable()
export class MediaService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAllActiveImageList(): Observable<any> {
    return this.http.get(`${this.apiURL}media/getAllActiveImageList`);
  }
  getImageDetailsById(countryId): Observable<any> {
    return this.http.post(
      `${this.apiURL}media/getImageDetailsById`,
      countryId,
      { headers }
    );
  }

  getAllActiveVideoList(): Observable<any> {
    return this.http.get(`${this.apiURL}media/getAllActiveVideoList`);
  }
  getVideoDetailsById(countryId): Observable<any> {
    return this.http.post(
      `${this.apiURL}media/getVideoDetailsById`,
      countryId,
      { headers }
    );
  }

  getAllNewsList(): Observable<any> {
    return this.http.get(`${this.apiURL}media/getAllNewsList`);
  }
  getNewsDetailsById(countryId): Observable<any> {
    return this.http.post(`${this.apiURL}media/getNewsDetailsById`, countryId, {
      headers,
    });
  }
}

import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { Meta } from "@angular/platform-browser";

import { HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";

import { BlogService } from "../../../services/blog/blog.service";
import { EventService } from "../../../services/event/event.service";
import { environment } from "../../../../environments/environment";

import { ToastrService } from "ngx-toastr";

class Feedback {
  constructor(
    public userName: string = "",
    public email: string = "",
    public phone: string = "",
    public subject: string = "",
    public comment: string = ""
  ) {}
}

@Component({
  selector: "app-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.scss"],
})
export class EventDetailsComponent implements OnInit {
  constructor(
    private blogService: BlogService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private meta: Meta,
    private toastr: ToastrService
  ) {
    route.params.subscribe((val) => {
      this.url = window.location.href;
      this.event_id = this.route.snapshot.paramMap.get("id");
      this.getEventDetailsById();
    });
  }

  @Input() itemCol = 1;
  @Input() true;
  @Input() loop = false;
  @Input() dots = false;
  @Input() nav = true;
  showNavigationArrows = true;
  showNavigationIndicators = true;
  // FOR MAP
  public lat: number;
  public lng: number;

  public currentUser;
  public event_id;
  public url;
  public userId = "";
  public created_at;
  public eventCity;
  public eventCountry;
  public eventState;
  public event_city_id;
  public event_country_id;
  public event_description;
  public event_end_date;
  public event_image;
  public event_location;
  public event_start_date;
  public event_state_id;
  public event_title;
  public id;
  public status;
  public updated_at;
  public activeEvent;

  public interes;
  public userLogin = false;
  public Interested = true;
  public shareLoad;

  public myRecaptcha = false;
  MsgAction;
  model: Feedback = new Feedback();
  @ViewChild("f") form: any;
  validCheck;
  Msg;
  spinnerLoader = false;
  public errorRequired = "is required.";
  public interestTitle;
  interestedForEvent: any;

  ngOnInit() {
    this.getEventDetailsById();
    this.titleService.setTitle("Event Details");
    this.getCurrentUser();
    this.url = window.location.href;
    this.event_id = this.route.snapshot.paramMap.get("id");

    this.shareLoad = true;

    if (this.userLogin) {
      this.interestTitle = "";
    } else {
      this.interestTitle = "Login to register interest";
    }
    window.scroll(0, 0);
  }

  OnChanges() {
    this.SetMeta();
  }

  SetMeta() {
    if (this.url !== "") {
      this.meta.addTag({ property: "og:url", content: this.url });
    }
    this.meta.addTag({ property: "og:type", content: "website" });
    this.meta.addTag({ property: "og:title", content: "Event Details" });
    this.meta.addTag({
      property: "og:description",
      content: "",
    });
    if (this.event_image !== "") {
      this.meta.addTag({ property: "og:image", content: this.event_image });
    }
  }
  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (this.currentUser) {
      this.userLogin = true;
      this.Interested = true;
      this.userId = this.currentUser.user_id;
    }
  }

  getEventDetailsById(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userId = this.currentUser ? this.currentUser.user_id : "";

    const activeEvent = new HttpParams()
      .set("event_id", this.event_id)
      .set("user_id", this.userId);
    this.eventService.getEventDetailsById(activeEvent).subscribe((response) => {
      this.activeEvent = response.data[0];
      this.created_at = this.activeEvent.created_at;
      this.eventCity = this.activeEvent.eventCity;
      this.eventCountry = this.activeEvent.eventCountry;
      this.eventState = this.activeEvent.eventState;
      this.event_city_id = this.activeEvent.event_city_id;
      this.event_country_id = this.activeEvent.event_country_id;
      this.event_description = this.activeEvent.event_description;
      this.event_end_date = this.activeEvent.event_end_date;
      this.event_image = this.activeEvent.event_image;
      this.event_location = this.activeEvent.event_location;
      this.event_start_date = this.activeEvent.event_start_date;
      this.event_state_id = this.activeEvent.event_state_id;
      this.event_title = this.activeEvent.event_title;
      this.id = this.activeEvent.id;
      this.status = this.activeEvent.status;
      this.updated_at = this.activeEvent.updated_at;
      this.lat = parseFloat(this.activeEvent.latitude);
      this.lng = parseFloat(this.activeEvent.longitude);

      this.interestedForEvent = response.data.is_user_interested;
      if (this.activeEvent) {
        this.SetMeta();
      }

      if (this.interestedForEvent === 0) {
        this.Interested = true;
      } else if (this.interestedForEvent === 1) {
        this.Interested = false;
      }
    });
  }

  markAsInterestedForEvent(): void {
    this.getCurrentUser();
    if (this.currentUser) {
      const interes = new HttpParams()
        .set("event_id", this.event_id)
        .set("user_id", this.userId);

      this.eventService.markAsInterestedForEvent(interes).subscribe((res) => {
        if (res.status == "true") {
          this.eventService
            .getEventDetailsById(interes)
            .subscribe((response) => {
              this.interes = response.data[0];
              this.interestedForEvent = response.data.is_user_interested;
              if ((response = true)) {
                this.toastr.success(
                  "You have successfully registered your interest.",
                  "",
                  {
                    positionClass: "toast-bottom-center",
                  }
                );
              }
              if (this.interestedForEvent === 0) {
                this.Interested = true;
              } else if (this.interestedForEvent === 1) {
                this.Interested = false;
              }
              // this.Interested = false;
            });
        }
      });
    } else {
      this.userLogin = false;
    }
  }

  markAsUninterested(): void {
    this.getCurrentUser();
    if (this.currentUser) {
      const interes = new HttpParams()
        .set("event_id", this.event_id)
        .set("user_id", this.userId);
      this.eventService.markAsUninterested(interes).subscribe((res) => {
        if (res.status == "true") {
          this.eventService
            .getEventDetailsById(interes)
            .subscribe((response) => {
              this.interes = response.data[0];
              this.interestedForEvent = response.data.is_user_interested;
              if ((response = true)) {
                this.toastr.success(
                  "You have unregistered your interest for the event.",
                  "",
                  {
                    positionClass: "toast-bottom-center",
                  }
                );
              }
              if (this.interestedForEvent === 0) {
                this.Interested = true;
              } else if (this.interestedForEvent === 1) {
                this.Interested = false;
              }
              // this.Interested = true;
            });
        }
      });
    } else {
      this.userLogin = false;
    }
    if (this.interestedForEvent === 0) {
      this.Interested = false;
    } else if (this.interestedForEvent === 1) {
      this.Interested = true;
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    this.getCurrentUser();
    const data = new HttpParams()
      .set("event_id", this.event_id)
      .set("userName", this.model.userName)
      .set("email", this.model.email)
      .set("phone", this.model.phone)
      .set("subject", this.model.subject)
      .set("comment", this.model.comment);
    this.eventService.eventFeedback(data).subscribe((response) => {
      const error = response.error;
      if ((response = true)) {
        this.toastr.success("Thank you for your feedback.", "", {
          positionClass: "toast-bottom-center",
        });
        this.validCheck = false;
        this.form.reset();
        this.myRecaptcha = true;
      } else {
        this.toastr.error(error);
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }

  // FOR PAGE SHARE
  public metaUrl = window.location.href;
  public metaTitle = "WAVF events";
  public metaDescription =
    "WAVF is a non-profit organization against the offence of Voyeurism registered in India. We are a group of public spirited persons from various fields aiming to create a Voyeurism-free world where the privacy of every individual is protected.";
  public metaImage = environment.imageUrlShare + "wavf.png";
  public metaTags = "WAVF, law, lawbook, voyeurism";
}

import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  HostListener,
  OnInit,
} from "@angular/core";

@Directive({
  selector: "[appDOB]",
})
export class DOBDirective {
  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}
  date = new Date();
  maxDate =
    new Date().getFullYear().toString() +
    "-0" +
    (new Date().getMonth() + 1).toString() +
    "-" +
    new Date().getDate().toString();

  ngOnInit() {
    this.renderer.setAttribute(
      this.hostElement.nativeElement,
      "max",
      this.maxDate
    );
  }
}

import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  constructor(private team:UsersService) { }
  public data : any;
  @Input() itemCol:number;
  @Input() autoplay;
  @Input() loop = false;
  @Input() dots = false;
  @Input() nav = false;

  

  ngOnInit() {
    this.data = this.team.team;
  }

}

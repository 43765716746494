import { Component, OnInit, Input, Pipe, PipeTransform } from "@angular/core";
import { EventService } from "../../services/event/event.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-upcoming",
  templateUrl: "upcoming.component.html",
})
export class UpcomingComponent implements OnInit {
  constructor(private eventService: EventService) { }
  showNavigationArrows = true;
  showNavigationIndicators = true;

  @Input() itemCol = 1;

  @Input() loop = true;
  @Input() dots = false;
  @Input() nav = true;
  @Input() upcoming;

  mySlideImages = [1, 2, 3].map(
    (i) => `https://picsum.photos/640/480?image=${i}`
  );

  events;
  event_image = "event_image/";
  public imageUrl = environment.imageUrl;
  eventImg = this.imageUrl + this.event_image;
  options = {
    items: this.itemCol,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
    loop: this.loop,
    autoplay: true,
    margin: 10,
    dots: this.dots,
    nav: this.nav,
  };

  ngOnInit() {
    this.events;
    this.eventImg;
    this.getAllUpcomingEventList();
  }

  getAllUpcomingEventList() {
    this.eventService.getAllUpcomingEventList().subscribe((response) => {
      this.events = response.data;
    });
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-audit-authority",
  templateUrl: "./audit-authority.component.html",
  styleUrls: ["./audit-authority.component.scss"],
})
export class AuditAuthorityComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}

import {
  NgModule,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormsModule, FormGroup, FormControl, NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { ToastrService } from "ngx-toastr";

import { MainService } from "../../services/main.service";
class Faq {
  constructor(
    public user_name: string = "",
    public user_email: string = "",
    public user_phone: string = "",
    public user_question: string = "",
    public user_question_details: string = ""
  ) {}
}
@Component({
  selector: "app-forum",
  templateUrl: "./forum.component.html",
  styleUrls: ["./forum.component.scss"],
})
export class ForumComponent implements OnInit {
  constructor(
    private mainService: MainService,
    private toastr: ToastrService
  ) {}
  onScriptLoad() {}
  onScriptError() {}
  model: Faq = new Faq();
  @ViewChild("f") form: any;
  public errorRequired = "is required.";
  validCheck;
  myRecaptcha;
  states;
  MsgAction;
  Msg;
  spinnerLoader = false;
  ngOnInit() {}

  numberOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    let data = new HttpParams()
      .set("user_name", this.model.user_name)
      .set("user_email", this.model.user_email)
      .set("user_phone", this.model.user_phone)
      .set("user_question", this.model.user_question)
      .set("user_question_details", this.model.user_question_details);
    const urlApi = "user/userQuestion";
    this.mainService.postApi(urlApi, data).subscribe((response: any) => {
      this.states = response.states;
      this.spinnerLoader = true;
      const error = response.error;
      if ((this.states = true)) {
        this.toastr.success(
          "Thank you for your quetion. We will get back to you soon.",
          "",
          { positionClass: "toast-bottom-center" }
        );
        this.validCheck = false;
        this.form.reset();
        this.myRecaptcha = true;
      } else {
        this.toastr.error(error);
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
}

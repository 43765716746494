import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  HostListener,
  OnInit,
} from "@angular/core";

@Directive({
  selector: "[textOnly]",
})
export class TextOnlyDirective {
  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}

  ngOnInit() {
    this.renderer.setAttribute(
      this.hostElement.nativeElement,
      "pattern",
      "/^[a-zA-Zs]*$/"
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { BlogService } from "../../services/blog/blog.service";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-recent",
  templateUrl: "./recent.component.html",
})
export class RecentComponent implements OnInit {
  constructor(private blogService: BlogService) {}
  public activeBlog;
  blog_image = "blog_image/";
  public imageUrl = environment.imageUrl;
  ngOnInit() {
    this.getBlog();
  }
  getBlog() {
    this.blogService.getBlog().subscribe((response) => {
      this.activeBlog = response.data;
    });
  }
}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { AuthService } from "angular4-social-login";
import { HttpParams } from "@angular/common/http";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";

import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angular4-social-login";
import { SignupService } from "../../services/signup/signup.service";
import { AlertService } from "../../services/alert.service";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class LoginComponent implements OnInit {
  @Output() dismissModal = new EventEmitter<string>();
  @Output() loggedIn = new EventEmitter<string>();
  dismiss() {
    this.dismissModal.next();
  }
  constructor(
    private authService: AuthService,
    private signupService: SignupService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  closeResult: string;
  @ViewChild("lgModal") modal: ElementRef;
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }
  close() {
    this.modalService.dismissAll();
  }
  forgotPasswordForm: FormGroup;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error;
  spinnerLoader = false;
  user: any;
  public userrole = "2";
  public one = "1";

  async signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    await this.authService.authState.subscribe((users) => {
      if (users && users.authToken && users.provider == "GOOGLE") {
        this.user = users;
      }
      if (users && users.provider == "GOOGLE") {
        this.onLoginWithGoogle();
      }
    });
  }

  onLoginWithGoogle(): void {
    let userName = this.user.name;
    let name = userName.split(" ");
    const data = new HttpParams()
      .set("google_profile_image", this.user.photoUrl)
      .set("google_id", this.user.id)
      .set("google_auth_token", this.user.authToken)
      .set("firstname", name[0])
      .set("lastname", name[1])
      .set("email", this.user.email)
      .set("interestedAsPanelMember", this.one)
      .set("interestedAsVolunteer", this.one)
      .set("userrole", this.userrole);
    const url = "user/loginWithGoogle";

    this.signupService.postSocial(url, data).subscribe(
      (response: any) => {
        if (response.data && response.status == "true") {
          localStorage.setItem("currentUser", JSON.stringify(response.data[0]));
          this.loggedIn.next();
          this.dismiss();
        }
      },
      (error: any) => {
        console.error("Error :", error);
      }
    );
  }

  async signInWithFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    await this.authService.authState.subscribe((users) => {
      if (users && users.authToken && users.provider == "FACEBOOK") {
        this.user = users;
      }
      if (users && users.provider == "FACEBOOK") {
        this.onLoginWithFacebook();
      }
    });
  }

  onLoginWithFacebook(): void {
    const data = new HttpParams()
      .set("facebook_profile_image", this.user.photoUrl)
      .set("facebook_id", this.user.id)
      .set("fb_auth_token", this.user.authToken)
      .set("firstname", this.user.firstName)
      .set("lastname", this.user.lastName)
      .set("interestedAsPanelMember", this.one)
      .set("interestedAsVolunteer", this.one)
      .set("userrole", this.userrole);
    const url = "user/loginWithFacebook";
    this.signupService.postSocial(url, data).subscribe(
      (response: any) => {
        // const user = response.data[0];
        if (response.data && response.status == "true") {
          localStorage.setItem("currentUser", JSON.stringify(response.data[0]));
          this.loggedIn.next();
          this.dismiss();
        }
      },
      (error: any) => {
        console.error("Error :", error);
      }
    );
  }

  openStatic(e) {
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.open(e);
  }
  openStatic1(e) {
    this.close();
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.open(e);
  }

  ngOnInit() {
    this.user = "";
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      username: ["", Validators.required],
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = window.location.href;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get p() {
    return this.forgotPasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const data = new HttpParams()
      .set("email", this.f.username.value)
      .set("password", this.f.password.value);
    this.authenticationService
      .login(data)
      .pipe(first())
      .subscribe(
        (user) => {
          this.error = user.error;
          if ((user.status = true)) {
            localStorage.setItem("currentUser", JSON.stringify(user.data[0]));
            //
            this.dismiss();

            this.loggedIn.next();
            window.location.reload();
            this.router.navigate(["/profile"]);
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  onSubmit_ForgotPassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.spinnerLoader = true;
    const data = new HttpParams().set("email", this.p.username.value);
    this.authenticationService
      .forgotPassword(data)
      .pipe(first())
      .subscribe(
        (user) => {
          this.error = user.error;

          if (user.status == "true") {
            this.toastr.success(
              "Temporary password sent to your email id.",
              "",
              { positionClass: "toast-bottom-center" }
            );
            this.dismiss();
            this.router.navigate(["/"]);
            //this.loggedIn.next();
            //window.location.reload();
            this.close();
          } else {
            this.toastr.error(
              "This email address is does not exist with our system",
              "",
              { positionClass: "toast-bottom-center" }
            );
            this.close();
          }
          this.spinnerLoader = false;
        },
        (error) => {
          //this.alertService.error(error);
        }
      );
  }
}

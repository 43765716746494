import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-icard",
  template: ` <a class="dropdown-item" (click)="downloadIcard()">I-Card</a> `,
})
export class IcardComponent implements OnInit {
  constructor(
    private mainService: MainService,
    private titleService: Title,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}
  public currentUser;
  public user;
  public user_id;
  public imageUrl = environment.apiUrl;
  public profile_img = "user_profile_images/";
  public iCard_url = "user_icard/";
  public imagePath;
  public iCard_pdf;
  public imgURL;
  public iCardUrl;

  ngOnInit() {
    this.getCurrentUser();
  }
  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser == null) {
      this.router.navigate(["/home"]);
    }
  }

  downloadIcard() {
    this.getCurrentUser();
    if (
      this.currentUser.firstname != "" &&
      this.currentUser.lastname != "" &&
      this.currentUser.dateofbirth != ""
    ) {
      let data = new HttpParams().set("userId", this.currentUser.user_id);
      const url = "user/generate_icard";
      this.mainService.postApi(url, data).subscribe((response: any) => {
        this.iCard_pdf = response.data;
        if (response.status == "true") {
          this.iCardUrl = this.imageUrl + this.iCard_url + this.iCard_pdf;

          var link = document.createElement("a");
          link.href = this.iCardUrl;
          link.target = "_blank";
          link.download = this.iCard_pdf;
          link.click();
          this.toastr.success("Your i-card successfully download.");
        } else {
          this.toastr.error("Something went wrong");
        }
      });
    } else {
      this.toastr.error(
        "Unable to download i-card. Please check your personal details."
      );
    }
  }
}

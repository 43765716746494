import { Component, OnInit } from "@angular/core";
import { MainService } from "../../services/main.service";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.scss"],
})
export class TestimonialsComponent implements OnInit {
  constructor(private mainService: MainService) {}
  public testimonials;
  // public testimonial_by;
  // public occupation;
  public;
  pageOfItems: Array<any>;
  p: number = 1;
  collection: any[];
  items;
  test;
  public imageUrl = environment.imageUrl_Testimonial;

  ngOnInit() {
    this.mainService.getTestimonials().subscribe((res) => {
      console.log("ttt", res);

      this.collection = res.data;
    });
    window.scroll(0, 0);
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getTestimonials() {}
}

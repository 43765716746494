import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ScriptLoaderService } from "angular-google-recaptcha";

import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
import { ToastrService } from "ngx-toastr";

class Accredetation {
  constructor(
    public pledgename: string = "",
    public organization: string = "",
    public registrationno: string = "",
    public businessType: string = null,
    public address: string = "",
    public country: string = null,
    public state: string = null,
    public city: string = null,
    public pincode: string = "",
    public mobileNumber: string = "",
    public email: string = "",
    public licence_copy: string = "",
    public police_licence_copy: string = "",
    public accredetationType: string = "",
    public accredationTypeSelf: any = true,
    public accredationTypeWavf: any = false,
    public accredationTypePolice: any = false,
    public policeVerifiedCertificate: boolean = false,
    public pledge_checkbox: any = false
  ) {}
}
@Component({
  selector: "app-accredetation",
  templateUrl: "./accredetation.component.html",
  styleUrls: ["./accredetation.component.scss"],
})
export class AccredetationComponent implements OnInit {
  pdf = "../../assets/lesson2.pdf";
  public imagePath;
  public imagePath2;
  imgURL: any;
  imgURLPoliceCertificate;
  any;
  public message: string;
  extention;
  police_extention;
  pledge_checkbox;
  constructor(
    private mainService: MainService,
    private ScriptLoaderService: ScriptLoaderService,
    private ElementRef: ElementRef,
    private titleService: Title,
    private toastr: ToastrService
  ) {}

  public businessTypes;
  public myRecaptcha;
  model: Accredetation = new Accredetation();
  @ViewChild("f") form: any;
  @ViewChild("myRecaptcha1") myRecaptcha1: any;
  public errorRequired = "is missing";
  file;
  filePolice;
  MsgAction = false;
  Msg: any;
  countrys;
  status;
  states;
  citys;
  validCheck;
  spinnerLoader = false;
  citysStatus = false;

  ngOnInit() {
    this.titleService.setTitle("Get Accredetation ");
    this.businessTypes;
    this.getAllCountry();
    this.getAllEntity();
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    window.scroll(0, 0);
  }

  getAllCountry() {
    this.mainService.getAllCountry().subscribe((response) => {
      this.countrys = response.data;
    });
  }

  getAllEntity() {
    this.mainService.getAllEntity().subscribe((response) => {
      this.businessTypes = response.data;
    });
  }

  getAllStatesByCountryId(countryId) {
    let data = new HttpParams().set("countryId", countryId);
    this.mainService
      .getAllStatesByCountryId(data)
      .subscribe((response: any) => {
        this.states = response.data;
      });
  }

  getAllCityByStateId(stateId) {
    let data = new HttpParams().set("stateId", stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;
      this.citysStatus = response.status;
    });
  }
  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    let ext = files[0].name.split(".").pop();
    this.extention = "." + ext;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  previewPoliceCertificate(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    let ext = files[0].name.split(".").pop();
    this.police_extention = "." + ext;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath2 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURLPoliceCertificate = reader.result;
    };
  }

  takePledge() {
    if (!this.model.pledge_checkbox) {
      this.model.pledge_checkbox = true;
    }
  }
  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }

    if (!this.model.pledge_checkbox) {
      this.toastr.error("Please take a pledge and click on checkbox.", "", {
        positionClass: "toast-bottom-center",
      });
      return;
    }

    if (
      !this.model.accredationTypeSelf &&
      !this.model.accredationTypeWavf &&
      !this.model.accredationTypePolice
    ) {
      this.toastr.error("Please select at least one verification type.", "", {
        positionClass: "toast-bottom-center",
      });
      return;
    }

    this.spinnerLoader = true;
    let data = new HttpParams()
      .set("pledgename", this.model.pledgename)
      .set("name", this.model.organization)
      .set("registrationno", this.model.registrationno || "")
      .set("business_entity", this.model.businessType)
      .set("address", this.model.address)
      .set("country", this.model.country)
      .set("state", this.model.state)
      .set("city", this.model.city)
      .set("zipcode", this.model.pincode)
      .set("contactno", this.model.mobileNumber)
      .set("email", this.model.email)
      .set("accredationTypeSelf", this.model.accredationTypeSelf)
      .set("accredationTypeWavf", this.model.accredationTypeWavf)
      .set("accredationTypePolice", this.model.accredationTypePolice)
      .set("website", "www.wavf.org");

    //.set('licence_copy', this.imgURL)
    //.set('police_licence_copy', this.imgURLPoliceCertificate)
    //.set('extention', this.extention)
    //.set('police_extention', this.police_extention)

    if (this.model.accredationTypePolice) {
      // this.downloadPDF();
    }
    const urlApi = "accredation/createAccredation";
    this.mainService.postApi(urlApi, data).subscribe((response: any) => {
      this.status = response.status;
      const error = response.error;
      if (response.status == "true") {
        this.toastr.success(
          "Done. Certificate has been sent to your Email address.",
          "",
          { positionClass: "toast-bottom-center" }
        );
        this.form.reset();
        this.model.pledge_checkbox = false;
        this.imgURL = null;
        this.imgURLPoliceCertificate = null;
        this.myRecaptcha = true;
      } else {
        this.toastr.error("Something went wrong.", error, {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  downloadPDF() {
    var link = document.createElement("a");
    link.href = this.pdf;
    link.target = "_blank";
    link.download = "PoliceVerified.pdf";
    link.click();
    this.toastr.success("Your form successfully downloaded.");
  }
}

import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class CarouselComponent implements OnInit {
  @Input() showCaption: boolean;
  showNavigationArrows = true;
  showNavigationIndicators = false;

  public slider;
  // imageSlider = [
  // {
  //   type: "image",
  //   image: "assets/assets/images/slider/1.jpg",
  //   title: "World Anti Voyeurism Forum",
  //   details: "Public spirited members for Anti Voyeurism ",
  //   button: [
  //     {
  //       name: "Join With Us",
  //       url: "/volunteer",
  //       style: "style-2",
  //     },
  //   ],
  // },

  // ];
  videoSlider = [
    {
      type: "video",
      // video: "https://www.dctinc.com/wp-content/uploads/2018/09/banner.mp4",
      video:
        "https://digitalinstitutecdnendpoint.azureedge.net/minoractsvideo/Intro%20Dec%202020%201.96%20MB.mp4",
      title: "World Anti Voyeurism Forum",
      details: "",
      button: [
        {
          name: "Join With Us",
          url: "volunteer",
          style: "style-2",
        },
      ],
    },
  ];

  ngOnInit() {
    // if (typeof window.orientation !== "undefined") {
    //   console.log("afdas", window.orientation);
    //   this.slider = this.imageSlider;
    // } else {
    this.slider = this.videoSlider;
    // }
  }
}

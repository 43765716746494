import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { MainService } from "../../services/main.service";
import { ToastrService } from "ngx-toastr";

class OTP {
  constructor(public otp: string = "") {}
}

@Component({
  selector: "app-verify-mobile",
  templateUrl: "./verify-mobile.component.html",
  styleUrls: ["./verify-mobile.component.scss"],
})
export class VerifyMobileComponent implements OnInit {
  constructor(
    private mainService: MainService,
    private toastr: ToastrService
  ) {}

  @Output() dismissModal = new EventEmitter<string>();
  dismiss() {
    this.dismissModal.next();
    this.MsgAction = !this.MsgAction;
  }

  currentUser;
  spinnerLoader;
  MsgAction;
  Msg;
  error;
  status;
  validCheck = false;

  model: OTP = new OTP();
  @ViewChild("f") form: any;

  ngOnInit() {}

  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }

    this.getCurrentUser();
    this.spinnerLoader = true;
    const data = new HttpParams()
      .set("userId", this.currentUser.user_id)
      .set("otp", this.model.otp);
    const url = "profile/verifyMobile";
    this.mainService.postApi(url, data).subscribe((response: any) => {
      this.error = response.error;
      this.status = response.status;
      if (response.status == "true") {
        //this.Msg = "Your otp successfully changed.";
        this.form.reset();
        this.toastr.success(
          "Your mobile number is verified successfully.",
          "",
          { positionClass: "toast-bottom-center" }
        );
        this.dismiss();
      } else {
        this.toastr.error("Something went wrong", this.error, {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
}

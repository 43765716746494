import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { MediaService } from "../../services/media.service";
import { environment } from "../../../environments/environment";
import { Title } from "@angular/platform-browser";
import * as $ from "jquery";
import "magnific-popup";
@Component({
  selector: "app-video-gallery",
  templateUrl: "./video-gallery.component.html",
  styleUrls: ["./video-gallery.component.scss"],
})
export class VideoGalleryComponent implements OnInit {
  items = [];
  pageOfItems: Array<any>;
  p: number = 1;
  collection: any[];
  constructor(
    private mediaService: MediaService,
    private titleService: Title
  ) {}
  image = "/gallary_image/";
  public imageUrl = environment.imageUrl;

  public videos;
  @ViewChild("imgGallary") public imgElement: ElementRef;
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.imgElement.nativeElement) {
        $(this.imgElement.nativeElement).magnificPopup({
          delegate: "a",
          gallery: {
            enabled: true,
          },
          type: "iframe",
        });
      }
    }, 2000);
  }
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.titleService.setTitle("Video Gallery");

    this.mediaService.getAllActiveVideoList().subscribe((response) => {
      this.collection = response.data;
    });
    window.scroll(0, 0);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
}

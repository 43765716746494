import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-certification",
  templateUrl: "./certification.component.html",
  styleUrls: ["./certification.component.scss"],
})
export class CertificationComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scroll(0, 0);
  }
}

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

import { HttpParams } from "@angular/common/http";
import { Response } from "@angular/http/src/static_response";
import { Title } from "@angular/platform-browser";
import { MainService } from "../../services/main.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
class User {
  constructor(
    public firstname: string = "",
    public lastname: string = "",
    public address: string = "",
    public dateofbirth: string = "",
    public mobile: string = "",
    public email: string = "",
    public city: string = ""
  ) {}
}
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  constructor(
    private mainService: MainService,
    private titleService: Title,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.minDate = this.mainService.maxAge;
    this.maxDate = this.mainService.minAge;
  }

  closeResult: string;
  @ViewChild("lgModal") modal: ElementRef;
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  model: User = new User();
  @ViewChild("f") form: any;

  public currentUser;
  public user_profile_image;
  public newPassword;

  public firstname;
  public lastname;
  public address;
  public dateofbirth;
  public mobile;
  public email;
  public city;
  public cityName;
  public profileImage;
  public mobile_activate;
  public userRoleDetail;

  public user_id;
  public imageUrl = environment.apiUrl;
  public profile_img = "user_profile_images/";
  public iCard_url = "user_icard/";

  public imagePath;
  public iCard_pdf;
  public imgURL;
  public iCardUrl;
  public message: string;
  public extention;
  public stateId;
  public personalDetailsEdit = false;
  validCheck;
  citys;
  citysStatus;

  error;
  status;
  Msg;
  spinnerLoader;
  MsgAction;

  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");
    this.getCurrentUser();
    this.getUserDetailsByUserId();
  }
  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser == null) {
      this.router.navigate(["/home"]);
    }
  }
  editUserDetails() {
    this.getAllCityByStateId();
    this.personalDetailsEdit = true;
  }
  getUserDetailsByUserId() {
    this.getCurrentUser();
    let data = new HttpParams().set("userId", this.currentUser.user_id);
    if (data) {
      this.mainService
        .getUserDetailsByUserId(data)
        .subscribe((response: any) => {
          let user = response.data[0];

          this.model.firstname = user.firstname;
          this.model.lastname = user.lastname;
          this.model.address = user.address;
          this.model.dateofbirth = user.dateOfBirth;
          this.model.mobile = user.mobile;
          this.model.email = user.email;
          this.model.city = user.city;

          this.firstname = user.firstname;
          this.lastname = user.lastname;
          this.address = user.address;
          this.dateofbirth = user.dateOfBirth;
          this.mobile = user.mobile;
          this.email = user.email;
          this.city = user.city;

          this.userRoleDetail = user.userRoleDetail;
          this.profileImage = user.profileImage;
          if (this.profileImage != "") {
            this.imgURL = this.imageUrl + this.profile_img + this.profileImage;
          } else {
            //this.imgURL = this.imageUrl+this.profile_img+"no_image_found.jpg"
            this.imgURL =
              "https://www.interorossmoor.com/assets/images/no-image-found.png";
          }

          this.cityName = user.cityName;
          this.mobile_activate = user.mobile_activate;
          this.user_id = user.user_id;
          this.stateId = user.state_id;
        });
    }
  }
  preview(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    let ext = files[0].name.split(".").pop();
    this.extention = "." + ext;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;

      this.changeProfileImage();
    };
  }
  changeProfileImage() {
    this.getCurrentUser();
    let data = new HttpParams()
      .set("userId", this.currentUser.user_id)
      .set("user_profile_image", this.imgURL)
      .set("extention", this.extention);
    const url = "profile/changeProfileImage";
    this.mainService.postApi(url, data).subscribe((response: any) => {});
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // getAllStatesByCountryId
  getAllCityByStateId() {
    let data = new HttpParams().set("stateId", this.stateId);
    this.mainService.getAllCityByStateId(data).subscribe((response: any) => {
      this.citys = response.data;

      this.citysStatus = response.status;
    });
  }

  downloadIcard() {
    if (
      this.firstname != "" &&
      this.lastname != "" &&
      this.dateofbirth != "" &&
      this.mobile != "" &&
      this.address != ""
    ) {
      this.getCurrentUser();
      let data = new HttpParams().set("userId", this.currentUser.user_id);
      const url = "user/generate_icard";
      this.mainService.postApi(url, data).subscribe((response: any) => {
        this.iCard_pdf = response.data;
        this.status = response.status;
        if (response.status == "true") {
          this.iCardUrl = this.imageUrl + this.iCard_url + this.iCard_pdf;

          var link = document.createElement("a");
          link.href = this.iCardUrl;
          link.target = "_blank";
          link.download = this.iCard_pdf;
          link.click();
          this.toastr.success("Your i-card successfully downloaded.", "", {
            positionClass: "toast-bottom-center",
          });
        } else {
          this.toastr.error("Something went wrong.", "", {
            positionClass: "toast-bottom-center",
          });
        }
      });
    } else {
      this.toastr.error(
        "Unable to download i-card. Please check your personal details.",
        "",
        { positionClass: "toast-bottom-center" }
      );
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    this.getCurrentUser();
    let data = new HttpParams()
      .set("firstname", this.model.firstname)
      .set("lastname", this.model.lastname)
      .set("address", this.model.address)
      .set("dateofbirth", this.model.dateofbirth)
      .set("userId", this.currentUser.user_id);
    const url = "profile/updatePersonalInfo";
    this.mainService.postApi(url, data).subscribe((response: any) => {
      this.error = response.error;
      this.status = response.status;
      if (response.status == "true") {
        this.Msg = "Personal details successfully updated ";
        this.form.reset();
        this.imgURL = null;
        this.personalDetailsEdit = false;
        this.getUserDetailsByUserId();
      } else {
        this.Msg = "Something went wrong";
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
}

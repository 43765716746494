import {
  NgModule,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  constructor(private router: Router) {}
  @Output() dismissModal = new EventEmitter<string>();
  dismiss() {
    this.dismissModal.next();
  }
  ngOnInit() {}
}

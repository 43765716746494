import {
  Directive,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { MainService } from "../services/main.service";
@Directive({
  selector: "[BirthDate]",
})
export class BirthDate implements OnChanges {
  @Input() ngModel;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private element: ElementRef, private mainService: MainService) {
    const minAge = this.mainService.minAge;
    const maxAge = this.mainService.maxAge;
    this.element.nativeElement.setAttribute("min", maxAge);
    setTimeout(() => {
      this.element.nativeElement.setAttribute("max", minAge);
    }, 300);
  }
  ngOnChanges() {}
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResponseContentType } from "@angular/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
let headers: { "Content-Type": "application/x-www-form-urlencoded" };
@Injectable()
export class MainService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {
    const today = new Date();
    const minAge = 18;
    const maxAge = 100;
    const newminAge = new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate()
    );
    const newmaxAge = new Date(
      today.getFullYear() - maxAge,
      today.getMonth(),
      today.getDate()
    );

    this.minAge = {
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear() - minAge,
    };

    this.maxAge = {
      day: today.getDay(),
      month: today.getMonth(),
      year: today.getFullYear() - maxAge,
    };
  }
  public minAge;
  public maxAge;

  getAllCountry(): Observable<any> {
    return this.http.get(`${this.apiURL}base_controllers/getAllCountry`);
  }

  getAllStatesByCountryId(countryId): Observable<any> {
    return this.http.post(
      `${this.apiURL}base_controllers/getAllStatesByCountryId`,
      countryId,
      { headers }
    );
  }
  getAllCityByStateId(stateId): Observable<any> {
    return this.http.post(
      `${this.apiURL}base_controllers/getAllCityByStateId`,
      stateId,
      { headers }
    );
  }
  getAllVolunteerOptions(): Observable<any> {
    return this.http.get(`${this.apiURL}volunteer/getAllVolunteerOptions`);
  }

  getAllEntity(): Observable<any> {
    return this.http.get(`${this.apiURL}entity/getAllEntity`);
  }

  postApi(url, data): Observable<any> {
    return this.http.post(this.apiURL + url, data, { headers });
  }

  getUserDetailsByUserId(data): Observable<any> {
    return this.http.post(
      `${this.apiURL}profile/getUserDetailsByUserId`,
      data,
      { headers }
    );
  }

  sendDonationInfo(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/donate`, data, { headers });
  }

  getDonationStatus(donationStatus): Observable<any> {
    return this.http.post(
      `${this.apiURL}user/donation_status`,
      donationStatus,
      { headers, responseType: 'text' }
    );
  }

  downloadBookCount(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/downalod_our_book`, data, {
      headers,
    });
  }

  downloadLawCount(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/downalod_our_law`, data, {
      headers,
    });
  }
  getTotalVolunteers(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/getTotalVolunteers`, data, {
      headers,
    });
  }
  getCertifiedPrimises(data): Observable<any> {
    return this.http.post(`${this.apiURL}user/getCertifiedPremises`, data, {
      headers,
    });
  }

  getTestimonials(): Observable<any> {
    return this.http.get(`${this.apiURL}user/getTestimonials`);
  }
}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor() {}

  links = [
    // {
    //   'url' : '/home',
    //   'page_name' : 'Home'
    // },
    // {
    //   url: "/law-draft",
    //   page_name: "Law Draft"
    // },
    {
      url: "/",
      page_name: "About Us",
      dropdown: [
        {
          url: "/whatwedo",
          page_name: "What We Do",
        },
        // {
        //   url: "/patrons",
        //   page_name: "Our Patron"
        // },
        {
          url: "/testimonials",
          page_name: "Testimonials",
        },
      ],
    },
    {
      url: "/",
      page_name: "Resources",
      dropdown: [
        {
          url: "/book",
          page_name: "Our Book",
        },
        {
          url: "/lawdraft",
          page_name: "Our Proposed Law",
        },
        {
          url: "/blog",
          page_name: "Blog",
        },
      ],
    },
    {
      url: "",
      page_name: "Media",
      dropdown: [
        {
          url: "/photo-gallery",
          page_name: "Photo Gallery",
        },
        {
          url: "/video-gallery",
          page_name: "Video Gallery",
        },
        {
          url: "/in-news",
          page_name: "News",
        },
      ],
    },
    {
      url: "/events",
      page_name: "Events",
    },
    {
      url: "",
      page_name: "Join Us",
      dropdown: [
        {
          url: "/volunteer",
          page_name: "Become a Volunteer",
        },
        {
          url: "/certification",
          page_name: "Get Certified",
        },
        {
          url: "/auditauthority",
          page_name: "Become an Auditing Agency",
        },

        // {
        //   'url' : '/panel-member',
        //   'page_name' : 'Panel Member'
        // },
      ],
    },

    // {
    //   url: "/blog",
    //   page_name: "blog"
    // },
    {
      url: "/faq",
      page_name: "FAQ",
    },
    {
      url: "/contact",
      page_name: "Contact Us",
    },
    {
      url: "/donation",
      page_name: "Donate",
    },
  ];

  public wavf = [
    {
      img: "assets/images/spy-camera.png",
      // subtitle: "There may be a spy camera in the trial room",
    },
    {
      img: "assets/images/private-video.png",
      // subtitle: "Is your private video VIRAL?",
    },

    {
      img: "assets/images/blackmail.png",
      // subtitle: "Are you being blackmailed?",
    },
    {
      img: "assets/images/hidden-camera.png",
      // subtitle: "Varieties of spy cameras are easily avaialable at low costs",
    },
    // {
    //   img: "assets/assets/images/resource/technical.jpg",
    //   type: "icon-star",
    //   title: "Technical Support",
    //   subtitle: "Cyber and IT experts to help",
    //   description:
    //     "WAVF is equipped with a cyber and IT expert team which is constantly developing technologies to curb the menace of Voyeurism. Our team is also working on our Voyeurism website and Mobile App to help our NGO redress such incidents."
    // }
  ];

  public laws = [
    {
      img: "assets/assets/images/resource/awareness.jpeg",
      button: [
        {
          name: "supprot now",
          url: "#",
        },
      ],
      url: "",
      title: "Ban on porn sites",
      description:
        "Etiam vitae leo diam pellentesque portaed eleifend ultricies risu, vel rutrum era commodos Praesent finib congue euismod...",
    },
    {
      img: "assets/assets/images/resource/about1.jpg",
      button: [
        {
          name: "supprot now",
          url: "#",
        },
      ],
      url: "",
      title: "Ban on porn sites",
      description:
        "Etiam vitae leo diam pellentesque portaed eleifend ultricies risu, vel rutrum era commodos Praesent finib congue euismod...",
    },
    {
      img: "assets/assets/images/resource/about1.jpg",
      button: [
        {
          name: "supprot now",
          url: "#",
        },
      ],
      url: "",
      title: "Ban on porn sites",
      description:
        "Etiam vitae leo diam pellentesque portaed eleifend ultricies risu, vel rutrum era commodos Praesent finib congue euismod...",
    },
    {
      img: "assets/assets/images/resource/about1.jpg",
      button: [
        {
          name: "supprot now",
          url: "#",
        },
      ],
      url: "",
      title: "Ban on porn sites",
      description:
        "Etiam vitae leo diam pellentesque portaed eleifend ultricies risu, vel rutrum era commodos Praesent finib congue euismod...",
    },
  ];

  public team = [
    {
      img: "assets/assets/images/team/team3.jpg",
      name: "Adv. Dhyanjay Madwanna",
      post: "Chairman",
      social: [
        {
          socialLink: "https://www.facebook.com/madwanna",
          icon: "fa-facebook",
          name: "Facebook",
        },
        {
          socialLink: "#",
          icon: "fa-twitter",
          name: "Facebook",
        },
      ],
    },
    {
      img: "assets/assets/images/team/team3.jpg",
      name: "Miss. Natasha Martin",
      post: "CEO",
      social: [
        {
          socialLink: "https://www.facebook.com",
          icon: "fa-facebook",
          name: "Facebook",
        },
        {
          socialLink: "#",
          icon: "fa-twitter",
          name: "Tweeter",
        },
      ],
    },
    {
      img: "assets/assets/images/team/team3.jpg",
      name: "Dr. Sandeep Patil",
      post: "International Coordinator",
      social: [
        {
          socialLink: "https://www.facebook.com/drsandeeppatilthedna",
          icon: "fa-facebook",
          name: "Facebook",
        },
        {
          socialLink: "#",
          icon: "fa-twitter",
          name: "Tweeter",
        },
      ],
    },
    {
      img: "assets/assets/images/team/team3.jpg",
      name: "Ms. Sreepriya Mundancheri",
      post: "Research Head",
      social: [
        {
          socialLink: "https://www.facebook.com",
          icon: "fa-facebook",
          name: "Facebook",
        },
        {
          socialLink: "#",
          icon: "fa-twitter",
          name: "Tweeter",
        },
      ],
    },
    {
      img: "assets/assets/images/team/team3.jpg",
      name: "Mr. Indrajeet Shinde",
      post: "Media Head",
      social: [
        {
          socialLink: "https://www.facebook.com",
          icon: "fa-facebook",
          name: "Facebook",
        },
        {
          socialLink: "#",
          icon: "fa-twitter",
          name: "Tweeter",
        },
      ],
    },
  ];
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { Response } from "selenium-webdriver/http";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { BlogService } from "../../../services/blog/blog.service";
import { EventService } from "../../../services/event/event.service";
import { environment } from "../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../../../services/authentication.service";
class Blog {
  constructor(public userComments: string = "") {}
}
@Component({
  selector: "app-blog-details",
  templateUrl: "./blog-details.component.html",
  styleUrls: ["./blog-details.component.scss"],
})
export class BlogDetailsComponent implements OnInit {
  constructor(
    private blogService: BlogService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private toastr: ToastrService,
    private auth: AuthenticationService
  ) {
    route.params.subscribe((val) => {
      this.url = window.location.href;
      this.blog_id = this.route.snapshot.paramMap.get("id");
      this.getBlogDetailsById();
    });
  }

  public activeBlog;
  public upcoming;
  public Msg;
  public blogComments;
  public comments;
  public blogTitle;
  public blogImage;
  public blogCreated_at;
  public blogContent = "";
  public url;
  public blog_image = "blog_image/";
  public imageUrl = environment.imageUrl;
  public profile_img = "profile_image/";
  public blog_id;
  public currentUser;
  public userId = "";

  // FOR PAGE SHARE
  public metaUrl = window.location.href;
  public metaTitle = "WAVF blogs";
  public metaDescription =
    "Beware and be alert! You are being secretly watched! Most people have an expectation of privacy in places like our homes, hotel rooms, bathrooms, trial rooms, etc. But what if we tell you, that this might not always be the case? ";
  public metaImage = environment.imageUrlShare + "wavf.png";
  public metaTags =
    "WAVF, AntiVoyeurism, lawbook, modellaw, privacy, NGO, cybersecurity, peepingtom";

  spinnerLoader = false;
  status;
  MsgAction;
  model: Blog = new Blog();
  @ViewChild("f") form: any;
  validCheck;
  public errorRequired = "is required.";
  public imgUserProfiles = environment.imgUserProfiles;
  ngOnInit() {
    let element = document.querySelector(".navbar");
    element.classList.replace("theme_menu_1", "theme_menu");

    this.titleService.setTitle("Blog Details");
    this.getCurrentUser();
    this.url = window.location.href;
    this.blog_id = this.route.snapshot.paramMap.get("id");
    this.getBlogDetailsById();
    this.metaDescription = this.blogContent.substring(0, 80);
    window.scroll(0, 0);
  }
  getCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser) {
      this.userId = this.currentUser.user_id;
    }
  }

  getBlogDetailsById(): void {
    const activeBlog = new HttpParams().set("blog_id", this.blog_id);
    this.blogService.getBlogDetailsById(activeBlog).subscribe((response) => {
      this.activeBlog = response.blogInfo[0];
      this.blogTitle = this.activeBlog.name;
      this.blogImage = this.activeBlog.image;
      this.blogCreated_at = this.activeBlog.created_at;
      this.blogContent = this.activeBlog.content;
      this.blogComments = response.blogCommentsInfo;
      this.comments = JSON.stringify(this.blogComments);
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.validCheck = true;
      return;
    }
    this.spinnerLoader = true;
    this.getCurrentUser();
    const data = new HttpParams()
      .set("blog_id", this.blog_id)
      .set("user_id", this.userId)
      .set("blog_comment", this.model.userComments);
    this.blogService.submitComments(data).subscribe((response) => {
      this.status = response;
      if ((response = true)) {
        this.toastr.success(
          "Thank you for your comment! Your comment will be displayed after approval from WAVF",
          "",
          {
            positionClass: "toast-bottom-center",
          }
        );
        this.validCheck = false;
        this.form.reset();
      } else {
        this.toastr.error("Something went wrong.", "", {
          positionClass: "toast-bottom-center",
        });
      }
      this.spinnerLoader = false;
      this.MsgAction = true;
    });
  }
  loginPopup() {
    this.auth.loginPopup("Login");
  }
  signPopup() {
    this.auth.loginPopup("signup");
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-alert-msg",
  templateUrl: "./alert-msg.component.html",
  styleUrls: ["./alert-msg.component.scss"],
})
export class AlertMsgComponent implements OnInit {
  constructor() {}
  @Input() status: boolean;
  @Input() MsgAction: boolean;
  @Input() Msg: boolean;
  @Output() counterAction = new EventEmitter<boolean>();
  ngOnInit() {}
  close() {
    this.counterAction.emit((this.MsgAction = false));
  }
}
